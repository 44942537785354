<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          :prev="chapters[6].link + '/:' + chapters[6].pages.length"
                          next="" :prevTitle="chapters[6].title"
                          nextTitle=""></SingleSidebarChapter>
</template>

<script>
    import SingleSidebarChapter from '../components/layouts/SingleSidebarChapter.vue'
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import content from "../assets/ChapterList.json";

    export default {
        name: "Chapter7",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                chapters: [],
                currentChapter: {},
                dataset: '',
                accuracy: ''
            }
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[7];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
                this.currentChapter.pages[i].summary = [];
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Aber wie gut ist unser Netz nach dem Training?', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Um das herauszufinden, gibt es eine Methode, die dem Netz ' +
                        'Beispielbilder zeigt und dabei speichert, wie viel % der Antworten korrekt waren.', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('code-element',
                {
                    content: [
                        'test_loss, test_acc = model.evaluate(test_images,  test_labels, verbose=1)',
                        'print("Test accuracy:", test_acc)']
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die zweite Zeile gibt die Genauigkeit in jedem Schritt aus: ', [])
                }, ''));
            this.getAccuracy();
            this.currentChapter.pages[0].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', ' Genau für diesen Zweck brauchen wir ein Testset, damit wir sicher ' +
                'sein können, dass das Netz die Bilder noch nie vorher gesehen und sich einfach gemerkt hat.', ['p-large'])
                }, ''));


            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Hier kannst Du noch einmal das Mini-Spiel selbst spielen, ' +
                        'aber diesmal trittst Du gegen das Netz an. Dann kannst Du wahrscheinlich besser einschätzen, wie ' +
                        'gut es wirklich ist.', [])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('classification-game', {playAgainstNet: true}, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Es gibt eine Webseite, auf der man sogar selber Zahlen schreiben ' +
                'kann, die ein Netz dann interpretiert: ', ['p-large'])
                }, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('link-button',
                {
                    text: 'Selber Zahlen schreiben!',
                    link: 'https://www.cs.ryerson.ca/~aharley/vis/fc/',
                    color: '#A8B764',
                }, ''));

            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Künstliche Neuronale Netze sind aus einzelnen Knoten aufgebaut. ' +
                        'Diese einzelnen Knoten entsprechen dem Modell des Perceptrons. Aus den Knoten können ' +
                        'beliebig komplexe Netze zusammengesetzt werden.', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Wissen eines Netzes steckt in den Gewichten der ' +
                        'Verbindungen zwischen den Knoten. Indem diese Gewichte mit dem Backpropagation-Algorithmus ' +
                        'angepasst werden, können viele verschiedene Aufgaben anhand von Beispielen gelernt werden. ' +
                        '(Mathematisch bedeutet das, dass eine beliebige Funktion angenähert werden kann.)', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Netz kann nicht selbst entscheiden, was "richtig" und ' +
                        '"falsch" ist. Dazu müssen Menschen zum Beispiel Label an alle Kleidungsstücke oder Zahlen ' +
                        'schreiben (oder die Antwort berechnen).', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn Du noch mehr über Künstliche Neuronale Netze wissen ' +
                        'möchtest, kannst Du Dir die vielen Links ansehen, die im nächsten Kapitel zusammengetragen wurden.', [])
                }, ''));
            this.currentChapter.pages[2].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Du hast es geschafft!', ['p-large'])
                }, ''));

        },
        methods: {
            getAccuracy: function () {
                fetch(process.env.VUE_APP_PYTHON_SERVER_ADDRESS + '/' + this.dataset + '/acc', {
                    "method": "GET",
                    credentials: 'include'
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            alert("Server returned " + response.status + " : " + response.statusText);
                        }
                    })
                    .then(response => {
                        this.accuracy = 'Genauigkeit: ' + Number(response.acc).toPrecision(3) + ', Fehlerquote: ' +
                            Number(response.loss).toPrecision(3);
                        this.currentChapter.pages[0].content.push(new CustomComponent('info-element',
                            {
                                content: this.accuracy,
                                color: 'var(--light-green)'
                            }, ''));
                        this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                            {
                                content: new TextElementContent('p', 'Jetzt wissen wir, mit welcher Wahrscheinlichkeit das Netz eine ' +
                                    'Zahl oder ein Kleidungsstück richtig erkennt. Aber kann man sich darunter wirklich etwas vorstellen?', [])
                            }, ''));

                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
    }
</script>

<style scoped>

</style>