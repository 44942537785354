export default class PixelImage {
    dimension = 2;
    isFlattened = false;
    values = [];
    showNumbers = false;
    pixelSize = 40;
    showValues = true;


    constructor(dimension, isFlattened, values, showNumbers, pixelSize, showValues) {
        this.dimension = dimension;
        this.isFlattened = isFlattened;
        this.values = values;
        this.showNumbers = showNumbers;
        this.pixelSize = pixelSize;
        this.showValues = showValues;
    }
}