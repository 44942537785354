<template>
    <div class="main bg">
        <h1>Können Computer Zahlen erkennen?</h1>
        <div class="grid-list">
            <CustomImage v-for="i in images" v-bind:key="i" v-bind:is-network="true"></CustomImage>
        </div>

        <div class="w">
            <CustomButton text="Antwort finden!" color="#66A464" image="explore_black_24dp.svg" v-bind:is-color="false"
                          v-bind:is-dark="false" v-on:click="nextChapter()"></CustomButton>
        </div>

    </div>
    <div class="bg"></div>
</template>

<script>
    import CustomImage from "../components/CustomImage";
    import CustomButton from "../components/CustomButton";

    export default {
        name: "Home",
        components: {CustomButton, CustomImage},
        data() {
            return {
                images: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            }
        },
        methods: {
            nextChapter: function () {
                this.$router.push('/c0/:1');
            }
        }
    }
</script>

<style scoped>
    .main {
        grid-column-start: 2;
        color: var(--text-light);
        padding: 3em;
    }

    .bg {
        background-color: var(--text-dark);
    }

    .main h1 {
        margin: auto;
    }

    .w {
        margin: auto;
        width: 250px;
    }

    .grid-list {
        margin: 3em auto;
        display: grid;
        grid-template-columns: repeat(3, min-content);
        grid-template-rows: repeat(3, min-content);
        align-content: center;
        justify-content: space-evenly;
    }
</style>