<template>
    <div class="btn shadow" :class="{btnt: !iconBottom, btnb: iconBottom }" :style="{ backgroundColor: color}">
        <img class="btn-icon" :src="image" alt="i"/>
        <p class="btn-text">{{text}}</p>
    </div>
</template>

<script>
    export default {
        name: "CustomSquareButton",
        props: {
            text: String,
            image: String,
            color: String,
            iconBottom: Boolean,
        }
    }
</script>

<style scoped>
    .btn {
        display: grid;
        border-radius: 6px;
        align-content: center;
        justify-content: center;
        cursor: pointer;
    }

    .btnt {
        grid-template-columns: min-content;
        grid-template-rows: 30px min-content;
        grid-template-areas: 'icon' 'text';
    }

    .btnb {
        grid-template-areas: 'text' 'icon';
    }

    .btn-icon {
        width: 30px;
        height: 30px;
        margin: auto;
        grid-area: icon;
        padding: 6px;
    }

    .btn-text {
        padding: 5px 20px;
        text-align: center;
        margin: 0;
        white-space: nowrap;
        grid-area: text;
        max-width: 200px;
        overflow: hidden;
        text-overflow: "…";
    }

</style>