<template>
    <div class="btn shadow" :class="{ dark: isDark, accent: !isDark, btnl: !iconRight, btnr: iconRight }">
        <img class="btn-icon" v-if="!isColor" :src="image" alt="i"/>
        <div class="btn-color" v-if="isColor" :style="{ backgroundColor: color}"></div>
        <p class="btn-text">{{text}}</p>
    </div>
</template>

<script>
    export default {
        name: "CustomButton",
        props: {
            text: String,
            image: String,
            color: String,
            isColor: Boolean,
            isDark: Boolean,
            iconRight: Boolean,
        }
    }
</script>

<style scoped>
    .btn {
        display: grid;
        border-radius: 6px;
        align-items: center;
        cursor: pointer;
    }

    .btnl {
        grid-template-columns: 30px min-content;
        grid-template-areas: 'icon text';
        justify-items: flex-start;
    }

    .btnr {
        grid-template-areas: 'text icon';
        justify-items: flex-end;
    }

    .btnl .btn-icon {
        padding: 6px;
    }

    .btnr .btn-icon {
        padding: 6px;
    }

    .btn-icon {
        width: 30px;
        height: 30px;
        margin: auto;
        grid-area: icon;
    }

    .btn-color {
        width: 10px;
        height: 10px;
        padding: 8px;
        border-radius: 50%;
        margin: auto auto auto 10px;
        grid-area: icon;
    }

    .btn-text {
        padding: 5px 20px;
        text-align: center;
        margin: 0;
        white-space: nowrap;
        grid-area: text;
        max-width: 200px;
        overflow: hidden;
        text-overflow: "…";
    }

</style>