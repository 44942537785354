<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          prev="" :next="chapters[1].link" prevTitle=""
                          :nextTitle="chapters[1].title"></SingleSidebarChapter>
</template>

<script>
    import content from "../assets/ChapterList.json";
    import SingleSidebarChapter from "../components/layouts/SingleSidebarChapter";
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import ButtonListContent from "../models/ButtonListContent";

    export default {
        name: "Chapter0",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                chapters: [],
                currentChapter: {}
            }
        },
        created() {
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[0];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('two-col-layout',
                {
                    topCol: [
                        new CustomComponent('text-element',
                            {
                                content: new TextElementContent('p', 'Als erstes suchen wir uns ein gutes Beispiel, eine Aufgabe, die ' +
                                    'unser Netz lösen soll.', [])
                            }, '')
                    ],
                    leftCol: [
                        new CustomComponent('text-element',
                            {content: new TextElementContent('p', 'handgeschriebene', ['p-small', 'm-5'])}, ''),
                        new CustomComponent('text-element',
                            {content: new TextElementContent('h2', 'Ziffern', ['m-5'])}, ''),
                        new CustomComponent('custom-image', {isNetwork: false, path: 'digit.png'}, ''),
                        new CustomComponent('text-element',
                            {
                                content: new TextElementContent('p', 'In diesem Beispiel sollen handgeschriebene Ziffern ' +
                                    'von 0 bis 9 erkannt werden.', [])
                            }, ''),
                        new CustomComponent('text-element',
                            {
                                content: new TextElementContent('p', 'Dieses Beispiel ist ein Klassiker, dem Du vielleicht schon ' +
                                    'einmal begegnet bist. Die Arbeitsweise eines KNNs lässt sich daran gut in Bildern darstellen, ' +
                                    'die ein Mensch noch interpretieren kann.', ['p-small', 'm-5'])
                            }, ''),
                        new CustomComponent('button-list',
                            {
                                content: [new ButtonListContent('auswählen', false, false, 'star_rate_black_24dp.svg')],
                                isTwoCol: true,
                                actionType: 'choose-digit'
                            }, '')
                    ],
                    rightCol: [
                        new CustomComponent('text-element',
                            {content: new TextElementContent('p', 'Produktfotos von', ['p-small', 'm-5'])}, ''),
                        new CustomComponent('text-element',
                            {content: new TextElementContent('h2', 'Kleidung', ['m-5'])}, ''),
                        new CustomComponent('custom-image', {isNetwork: false, path: 'fashion.png'}, ''),
                        new CustomComponent('text-element',
                            {content: new TextElementContent('p', 'In diesem Beispiel sollen einzeln fotografierte Kleidungsstücke ' +
                            'in 10 Kategorien unterteiltwerden.', [])}, ''),
                        new CustomComponent('text-element',
                            {content: new TextElementContent('p', 'Dieses Beispiel ist hoffentlich noch nicht so durchgekaut. Es ' +
                            'ist allerdings auch komplexer und wahrscheinlich nicht an jeder Stelle so gut nachvollziehbar,' +
                            ' wie das KNN seine Entscheidungen trifft.', ['p-small', 'm-5'])}, ''),
                        new CustomComponent('button-list',
                            {
                                content: [new ButtonListContent('auswählen', false, false, 'star_rate_black_24dp.svg')],
                                isTwoCol: true,
                                actionType: 'choose-fashion'
                            }, '')
                    ],
                    botCol: []
                }, ''));
            this.currentChapter.pages[0].summary = 'Du kannst gerne hierher zurück kommen und das Beispiel wechseln.';
        }
    }
</script>

<style scoped>

</style>