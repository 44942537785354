<template>
    <div class="twocol">
        <div class="topcol">
            <h4 class="span2">Runde {{turn}} von 5</h4>
        </div>
        <div class="leftcol">
            <img class="image shadow span2" :src="image" alt="picture of clothing">
            <div class="shadow dark result" :class="{ green: correctAnswer === userAnswer && chosen,
                red: correctAnswer !== userAnswer && chosen }">
                <h4 class="m-5">Deine Antwort:</h4>
                <p class="m-5">{{userAnswer}}</p>
            </div>
            <div v-if="playAgainstNet" class="shadow dark result" :class="{ green: correctAnswer === netAnswer && chosen,
                red: correctAnswer !== netAnswer && chosen }">
                <h4 class="m-5">Antwort vom Netz:</h4>
                <p class="m-5" v-if="chosen">{{netAnswer}}</p>
            </div>
            <div class="shadow dark result">
                <h4 class="m-5">Offizielle Antwort:</h4>
                <p class="m-5" v-if="chosen">{{correctAnswer}}</p>
            </div>
        </div>
        <div class="rightcol">
            <ButtonList v-bind:content="createButtons()" v-bind:is-two-col="true" @action="chooseClass"
                        action-type="choose-label"></ButtonList>
            <CustomButton text="nächste Runde" image="sports_esports_black_24dp.svg" v-bind:is-color="false"
                          v-bind:is-dark="false" v-on:click="nextImage()"></CustomButton>
        </div>
    </div>
</template>

<script>
    import CustomButton from './CustomButton.vue';
    import ButtonListContent from "../models/ButtonListContent";
    import ButtonList from "./ButtonList";
    import MinigameTurnResult from "../models/MinigameTurnResult";
    import Dataset from "../models/Dataset";

    export default {
        name: "classification-game",
        components: {
            CustomButton,
            ButtonList
        },
        props: {
          playAgainstNet: Boolean
        },
        data() {
            return {
                dataList: new Dataset(process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + 'fashion/', process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + 'fashion_label/',
                    ['T-shirt/top', 'Hose', 'Pullover', 'Kleid', 'Jacke',
                        'Sandale', 'Hemd', 'Sneaker', 'Tasche', 'Stiefel'],
                    ['#D5BE95', '#EA655E', '#C86E90', '#946DAE', '#667BA5',
                        '#7ABBB7', '#66A464', '#A8B764', '#D2BA5A', '#D6A15C']),
                turn: 1,
                userAnswer: "",
                netAnswer: "",
                correctAnswer: "",
                score: 0,
                correctAnswerCount: 0,
                chosen: false,
                currentImage: 0,
                image: process.env.VUE_APP_PYTHON_SERVER_ADDRESS + '/',
                results: [],
                dataset: ''
            }
        },
        created() {
            // todo: this.currentChapter.pages[2].summary = 'Score!';
            this.dataset = this.$store.state.dataset;
            if (this.dataset === undefined) {
                this.dataset = 'digit';
            }
            const endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                + '/' + this.dataset + '/';
            const label_endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                + '/' + this.dataset + '_label/';
            const test_endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                + '/' + this.dataset + '/test/';
            const net_endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                + '/' + this.dataset + '/prediction/';
            let labels = [];
            if (this.dataset === 'digit') {
                labels = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            } else if (this.dataset === 'fashion') {
                labels = ['T-shirt/top', 'Hose', 'Pullover', 'Kleid', 'Jacke',
                    'Sandale', 'Hemd', 'Sneaker', 'Tasche', 'Stiefel'];
            }
            this.dataList = new Dataset(endpoint, label_endpoint, net_endpoint, test_endpoint, labels,
                ['#D5BE95', '#EA655E', '#C86E90', '#946DAE', '#667BA5',
                    '#7ABBB7', '#66A464', '#A8B764', '#D2BA5A', '#D6A15C']);
            this.randomImage();
            if(this.playAgainstNet){
                this.image = this.dataList._test_url + this.currentImage;
                this.getNetAnswer();
            }else {
                this.image = this.dataList._train_url + this.currentImage;
                this.getClass();
            }
        },
        methods: {
            createButtons: function () {
                let content = [];
                for (let i = 0; i < this.dataList._classList.length; i++) {
                    content.push(new ButtonListContent(this.dataList._classList[i], true, true,
                        this.dataList._colorList[i]));
                }
                return content;
            },
            randomImage: function () {
                this.currentImage = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
            },
            nextImage: function () {
                if (this.chosen) {
                    this.randomImage();
                    this.chosen = false;
                    this.userAnswer = '';
                    if(this.playAgainstNet){
                        this.image = this.dataList._test_url + this.currentImage;
                        this.getNetAnswer();
                    }else{
                        this.image = this.dataList._train_url + this.currentImage;
                        this.getClass();
                    }
                    this.turn += 1;
                }
            },
            chooseClass: function (c) {
                if (!this.chosen) {
                    this.userAnswer = c;
                    // console.log(c);
                    if (this.userAnswer === this.correctAnswer) {
                        this.correctAnswerCount += 1;
                    }
                    this.score = Math.round(this.correctAnswerCount / this.turn * 100);
                    this.chosen = true;
                    this.results.push(new MinigameTurnResult(this.image, this.correctAnswer, this.userAnswer));
                    this.$store.commit('setResults', this.results);
                }
            },
            getClass: function () {
                fetch(this.dataList._class_url + this.currentImage, {
                    "method": "GET",
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            alert("Server returned " + response.status + " : " + response.statusText);
                        }
                    })
                    .then(response => {
                        this.correctAnswer = this.dataList._classList[parseInt(response.label)];
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            getNetAnswer: function () {
                fetch(this.dataList._net_url + this.currentImage, {
                    "method": "GET",
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            alert("Server returned " + response.status + " : " + response.statusText);
                        }
                    })
                    .then(response => {
                        this.netAnswer = this.dataList._classList[parseInt(response.net_answer)];
                        this.correctAnswer = this.dataList._classList[parseInt(response.answer)];
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
    }
</script>

<style scoped>
    .twocol {
        display: grid;
        width: 100%;
        height: 100%;
    }

    .result {
        border-radius: 0.5em;
        margin: 10px;
        padding: 0.7em;
        white-space: nowrap;
        display: flex;
    }

    .topcol {
        width: 100%;
        grid-area: top-col;
        display: grid;
        justify-items: center;
        align-items: start;
    }

    .leftcol {
        grid-area: left-col;
        display: grid;
        justify-items: center;
        align-items: start;
    }

    .rightcol {
        grid-area: right-col;
        display: grid;
        justify-items: center;
        align-items: start;
    }

    @media only screen and (max-width: 1000px) {
        .twocol {
            grid-template-columns: auto;
            grid-template-areas: "top-col" "left-col" "right-col";
            grid-gap: 20px;
        }
    }

    @media only screen and (min-width: 1000px) {
        .twocol {
            grid-template-columns: auto auto;
            grid-template-areas: "top-col top-col" "left-col right-col";
            column-gap: 20px;
        }
    }
</style>