<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          :prev="chapters[3].link + '/:' + chapters[3].pages.length"
                          :next="chapters[5].link" :prevTitle="chapters[3].title"
                          :nextTitle="chapters[5].title"></SingleSidebarChapter>
</template>

<script>
    import SingleSidebarChapter from '../components/layouts/SingleSidebarChapter.vue'
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import PixelImage from "../models/PixelImage";
    import content from "../assets/ChapterList.json";

    export default {
        name: "Chapter4",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                chapters: [],
                currentChapter: {},
                horLine1: [0, 0, 1, 1],
                horLine2: [1, 1, 0, 0],
                vertLine1: [1, 0, 1, 0],
                vertLine2: [0, 1, 0, 1],
                white: [1, 1, 1, 1],
                largeImage: [],
                dataset: ''
            }
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.largeImage = Array.from({length: 784}, () => Math.round(Math.random()));
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[4];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
                this.currentChapter.pages[i].summary = [];
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Du kannst jetzt die Vorhersage selbst berechnen!', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 5,
                    showData: true,
                    nodeActive: true,
                    outputActive: true
                }
            ));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Gewichte wurden zufällig gesetzt. Genau so beginnt auch ' +
                        'das Netz beim Lernen. Das Ziel ist es, die Gewichte so anzupassen, dass für alle möglichen ' +
                        'Bilder, die dem Netz gezeigt werden, eine richtige Vorhersage gemacht wird. Dazu werden dem ' +
                        'Netz ganz viele Beispiele gezeigt und jedes Mal gesagt, was die richtige Lösung gewesen wäre.', [])
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Eine Berechnung vom Input zu einem Output heißt Forwardpropagation.', ['p-large'])
                }, ''));

            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Eine Möglichkeit, dem Netz vorzugeben, was die richtige Lösung ' +
                        'ist, sind Label. Dabei wird für jeden Input, also jedes Bild, das wir an das Netz geben ein ' +
                        'Label geschrieben.', [])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('pixel-grid',
                {
                    content: [
                        new PixelImage(2, false, this.horLine1, false, 40, true),
                        new PixelImage(2, false, this.horLine2, false, 40, true),
                        new PixelImage(2, false, this.vertLine1, false, 40, true),
                        new PixelImage(2, false, [0,0,0,1], false, 40, true),
                        new PixelImage(2, false, [0,1,1,0], false, 40, true)
                    ],
                    showLabel: true,
                    label: [1, 1, 0, 0, 0, 0]
                }
            ));
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Da das Netz nach seiner Aktivierungsfunktion nur eine 1 oder ' +
                        'eine 0 ausgeben kann, müssen unsere Label auch die Werte 1 oder 0 annehmen. Wir können aber ' +
                        'festlegen, dass eine 1 für eine horizontale Linie und eine 0 für alle anderen Kombinationen ' +
                        'steht (oder andersherum).', [])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'So kann das Netz sein Ergebnis mit dem Label vergleichen und ' +
                        'ausrechnen, wie groß der Fehler war. In unserem Fall ist der Fehler immer entweder 1 oder 0. ' +
                        'Wenn man eine andere Aktivierungsfunktion nimmt, kann der Fehler aber auch andere Werte annehmen.', [])
                }, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Netz berechnet die Differenz zwischen dem Output und dem Label ' +
                'und ermittelt so, wie groß der Fehler war.', ['p-large'])
                }, ''));

            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Jetzt da wir wissen, wie groß der Fehler war, müssen wir daraus ' +
                        'lernen. Das können wir, indem wir die Gewichte anpassen, sodass beim nächsten Mal, wenn wir ' +
                        'dieses Beispiel (oder ein ähnliches) sehen, das richtige Ergebnis heraus kommt.', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 5,
                    showData: true,
                    nodeActive: true,
                    outputActive: true
                }
            ));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wir könnten so lange per Hand und Bauchgefühl die Gewichte ' +
                        'anpassen, bis das Netz ausreichend gute Vorhersagen macht. Du kannst gerne einmal versuchen, ' +
                        'die Gewichte selbst anzupassen. Aber wäre es nicht praktisch, wenn ' +
                        'wir eine Möglichkeit hätten, auszurechen welche Gewichte um welchen Wert verändert werden müssen?', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Diese Möglichkeit ist der sogenannte Backpropagation-Algorithmus. ' +
                        'Dafür wird die Formel mit Ableitungen und ganz viel Mathe umgestellt, sodass rückwärts ' +
                        'für jedes Gewicht ausgerechnet werden kann, wie es verändert werden müsste, um den ' +
                        'richtigen Output zu erreichen.', [])
                }, ''));
            this.currentChapter.pages[2].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Der Backpropagation-Algorithmus passt die Gewichte des Netzes an und ' +
                'übernimmt so das Lernen.', ['p-large'])
                }, ''));

            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Mit dem Backpropagation-Algorithmus wissen wir, welche ' +
                        'Gewichte wir anpassen müssen, damit das Netz auf das eine, eben berechnete Bild optimiert wird. ' +
                        'Das bedeutet, dass wir beim nächsten Bild die ganzen Gewichte wieder ändern und dabei aber ' +
                        'alle Anpassungen der vorherigen Bilder wieder rückgängig machen könnten!', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wir brauchen also eine Möglichkeit, alle Fehler, die wir bis ' +
                        'jetzt gemacht haben, zu berücksichtigen. Ansonsten können wir uns nicht insgesamt verbessern, ' +
                        'sondern können immer nur das aktuelle Bild lernen.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Um das zu verhindern, wird eine sogenannte Loss-Funktion ' +
                        'definiert. Eine mögliche Funktion, um den gesamten Fehler über alle angesehenen Beispiele zu ' +
                        'berechnen, ist die mittlere quadratische Abweichung (mean square error, MSE).', [])
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Loss-Funktion sorgt dafür, dass sich das Netz insgesamt verbessert ' +
                'und nicht bei jedem Anpassen der Gewichte den vorherigen Fortschritt wieder rückgängig macht.', ['p-large'])
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('link-button',
                {
                    text: 'Video zum Thema',
                    link: 'https://www.youtube.com/watch?v=QBbC3Cjsnjg',
                    color: '#A8B764',
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('link-button',
                {
                    text: 'Artikel zum Thema',
                    link: 'https://www.analyticsvidhya.com/blog/2019/08/detailed-guide-7-loss-functions-machine-learning-python-code/',
                    color: '#A8B764',
                }, ''));
            // https://machinelearningmastery.com/how-to-choose-loss-functions-when-training-deep-learning-neural-networks/
            // https://www.youtube.com/watch?v=QBbC3Cjsnjg
            // https://www.analyticsvidhya.com/blog/2019/08/detailed-guide-7-loss-functions-machine-learning-python-code/

            this.currentChapter.pages[4].content.push(new CustomComponent('gradient-descent-game', {}, ''));
            this.currentChapter.pages[4].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Findest Du den Weg ins tiefste Tal?', ['p-large'])
                }, ''));

            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Gebirge ist für ein Netz durch die Loss-Funktion vorgegeben.',
                        [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Der sogenannte Optimizer berechnet die Steigung (also die ' +
                        'mögliche Verkleinerung des Gesamtfehlers) ' +
                        'und passt die Schrittweite an, sodass das Training nicht ewig in viel zu kleinen Schritten voran ' +
                        'geht, aber die Schritte auch nicht zu groß sind, sodass das Netz immer über das Minimum hinweg springt.',
                        [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn das Netz das tiefste Tal gefunden hat, hat es die ' +
                        'gewünschte Funktion (oder Aufgabe) so gut wie möglich gelernt.',
                        [])
                }, ''));
            this.currentChapter.pages[5].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Einer der bekanntesten Optimizer heißt Adam.', ['p-large'])
                }, ''));
            this.currentChapter.pages[5].summary.push(new CustomComponent('link-button',
                {
                    text: 'Video zum Thema',
                    link: 'https://www.youtube.com/watch?v=mdKjMPmcWjY',
                    color: '#A8B764',
                }, ''));
            this.currentChapter.pages[5].summary.push(new CustomComponent('link-button',
                {
                    text: 'Artikel zum Thema',
                    link: 'https://towardsdatascience.com/optimizers-for-training-neural-network-59450d71caf6',
                    color: '#A8B764',
                }, ''));
            // https://towardsdatascience.com/optimizers-for-training-neural-network-59450d71caf6
            // https://www.youtube.com/watch?v=mdKjMPmcWjY

            this.currentChapter.pages[6].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Percepton liefert eine Zahl als Ergebnis. Diese Zahl muss ' +
                        'nicht unbedingt nur die Werte 0 und 1 annehmen können. Trotzdem können mit einem Perceptron nur ' +
                        '2 Klassen voneinander unterschieden werden. Da liegt daran, dass das Perceptron nur in der Lage ' +
                        'ist, eine lineare Funktion zu lernen, also eine gerade Linie zwischen den Daten zu ziehen.',
                        [])
                }, ''));
            this.currentChapter.pages[6].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Auch unsere Aufgabe, vertikale von horizontalen Linien zu ' +
                        'unterscheiden, konnten wir mit einem einzelnen Perceptron nicht lösen, weil es ja die ganzen ' +
                        'anderen möglichen Kombinationen auch gibt!',
                        [])
                }, ''));
            this.currentChapter.pages[6].content.push(new CustomComponent('custom-image',
                {isNetwork: false, path: 'xor.jpg', width: 500}, ''));
            this.currentChapter.pages[6].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Als Rosenblatt das Perceptron vorgestellt hat, hat es dafür ' +
                        'viel Kritik erhalten. Es ist zum Beispiel nicht in der Lage, eine X-OR Funktion zu lernen ' +
                        '(eine grundlegende Operation in der Informatik), weil es nicht möglich ist, die Klassen mit ' +
                        'einer geraden Linie zu trennen (siehe Bild).',
                        [])
                }, ''));
            this.currentChapter.pages[6].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn das Perceptron nur zwei Klassen ereknnen kann, wie verwenden ' +
                'wir es dann für unsere Aufgabe der Zahlen oder Kleidung?', ['p-large'])
                }, ''));

        }
    }
</script>

<style scoped>

</style>