import App from './App.vue';
import {createApp} from 'vue';
import * as VueRouter from "vue-router";
import {createStore} from 'vuex';
import VueKonva from 'vue3-konva';

// import components for routing
import Home from './chapters/Home.vue'
import Chapter0 from './chapters/Chapter0.vue'
import Chapter1 from './chapters/Chapter1.vue'
import Chapter2 from './chapters/Chapter2.vue'
import Chapter3 from './chapters/Chapter3.vue'
import Chapter4 from './chapters/Chapter4.vue'
import Chapter5 from './chapters/Chapter5.vue'
import Chapter6 from './chapters/Chapter6.vue'
import Chapter7 from './chapters/Chapter7.vue'
import ANNMap from './chapters/ANNMap.vue'

const routes = [
    {path: '/', component: Home},
    {path: '/#/', component: Home},
    {path: '/c0/:page', component: Chapter0, props: true},
    {path: '/c0', redirect: '/c0/:1'},
    {path: '/c1/:page', component: Chapter1, props: true},
    {path: '/c1', redirect: '/c1/:1'},
    {path: '/c2/:page', component: Chapter2, props: true},
    {path: '/c2', redirect: '/c2/:1'},
    {path: '/c3/:page', component: Chapter3, props: true},
    {path: '/c3', redirect: '/c3/:1'},
    {path: '/c4/:page', component: Chapter4, props: true},
    {path: '/c4', redirect: '/c4/:1'},
    {path: '/c5/:page', component: Chapter5, props: true},
    {path: '/c5', redirect: '/c5/:1'},
    {path: '/c6/:page', component: Chapter6, props: true},
    {path: '/c6', redirect: '/c6/:1'},
    {path: '/c7/:page', component: Chapter7, props: true},
    {path: '/c7', redirect: '/c7/:1'},
    {path: '/c8/:page', component: ANNMap, props: true},
    {path: '/c8', redirect: '/c8/:1'},
];

// 3. Create the router instance and pass the `routes` option
const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHashHistory(),
    routes, // short for `routes: routes`
});

const app = createApp(App);

app.use(router);

export const store = createStore({
    state() {
        return {
            results: [],
            dataset: 'digit'
        }
    },
    getters: {},
    mutations: {

        setResults(state, newResults) {
            state.results = newResults;
        },
        setDataset(state, newDataset) {
            state.dataset = newDataset;
            // console.log(state.dataset);
        }

    },
    actions: {}
});
app.use(store);

app.use(VueKonva);

app.mount('#app');



