<template>
    <div class="info-content shadow" :style="infoStyle">
        <p class="icon m-0">i</p>
        <p class="content m-0">{{content}}</p>
    </div>
</template>

<script>
    export default {
        name: "expandable-info-element",
        props: {
            content: String,
            color: String
        },
        computed: {
            infoStyle() {
                return {
                    backgroundColor: `${this.color}`
                }
            },
        }
    }
</script>

<style scoped>
    .info-content {
        padding: 0.5rem;
        margin: 1em auto;
        width: 100%;
        border-radius: 1em;
        clip-path: circle(20px at 20px 30px);
        transition: all .3s ease-in-out;
        color: var(--text-dark);
        display: grid;
        grid-template-columns: 30px auto;
        align-items: center;
        justify-items: start;
        cursor: pointer;
    }

    .info-content:hover {
        clip-path: circle(80%);
    }

    .m-0 {
        margin: 0;
    }

    .icon {
        justify-self: center;
        align-self: start;
    }

    .content {
        margin-left: 10px;
    }

</style>