export default class TextElementContent {
    html = '';
    content = '';
    classList = [];


    constructor(html, content, classList) {
        this.html = html;
        this.content = content;
        this.classList = classList;
    }
}