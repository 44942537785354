<template>
    <div class="pixel-image" :style="{gridTemplate: template, maxHeight: mHeight}" :class="{shadow: !isFlattened}">
        <div class="pixel-tile" v-for="(v, index) in content.values.slice(0, flatCount)" v-bind:key="index"
             :class="{white: v === 1, black: v === 0, shadow: isFlattened}" :style="{width: width, height: width}">
            <p v-if="showNumbers || showValues" class="m-5">{{this.getContent(index)}}</p>
        </div>
        <div :style="{width: width, height: width}" v-if="isFlattened && dimension > flatCount">...</div>
        <div class="pixel-tile" :style="{width: width, height: width}"
             v-for="(v, index) in content.values.slice((dimension*dimension - flatCount), dimension*dimension)"
             v-bind:key="index" :class="{white: v === 1, black: v === 0, shadow: isFlattened}">
            <p v-if="showNumbers || showValues" class="m-5"> {{this.getContent(dimension*dimension - dimension +
                index)}}</p>
        </div>
    </div>
</template>

<script>
    import PixelImage from "../models/PixelImage";

    export default {
        name: "pixel-image",
        props: {
            content: PixelImage,
        },
        data() {
            return {
                flatCount: 4,
                style: Object,
                template: `repeat(9, ${this.content.pixelSize}px)`,
                mHeight: '80px',
                width: `${this.content.pixelSize}px`,
                isFlattened: false,
                dimension: 2,
                showNumbers: false,
                showValues: true
            }
        },
        created() {
            this.isFlattened = this.content.isFlattened;
            this.dimension = this.content.dimension;
            this.showNumbers = this.content.showNumbers;
            this.showValues = this.content.showValues;
            if (!this.isFlattened) {
                this.template = `repeat(${this.dimension},
                ${this.content.pixelSize}px) / repeat(${this.dimension}, ${this.content.pixelSize}px)`;
                this.mHeight = `${this.dimension * this.content.pixelSize}px`;
                this.flatCount = Math.floor(this.dimension * this.dimension / 2);
            } else {
                this.mHeight = `${9 * this.pixelSize}px`;
                if (this.dimension < this.flatCount) {
                    this.flatCount = this.dimension;
                }
            }

        },
        methods: {
            getContent: function (index) {
                if (this.showValues) {
                    return this.content.values[index];
                }
                return index;
            }
        }
    }
</script>

<style scoped>
    .pixel-image {
        display: grid;
        margin: 20px;
    }

    .pixel-tile {
        border: 1px solid black;
        display: grid;
        justify-items: center;
        align-items: center;
    }

    .white {
        background-color: white;
    }

    .black {
        background-color: black;
    }
</style>