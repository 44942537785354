<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          :prev="chapters[4].link + '/:' + chapters[4].pages.length"
                          :next="chapters[6].link" :prevTitle="chapters[4].title"
                          :nextTitle="chapters[6].title"></SingleSidebarChapter>
</template>

<script>
    import SingleSidebarChapter from '../components/layouts/SingleSidebarChapter.vue'
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import content from "../assets/ChapterList.json";

    export default {
        name: "Chapter5",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                chapters: [],
                currentChapter: {},
                dataset: ''
            }
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[5];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
                this.currentChapter.pages[i].summary = [];
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn ein einzelnes Perceptron eine lineare ' +
                        'Funktion abbilden kann, was passiert dann, wenn man einfach mehrere Perceptrons hintereinander ' +
                        'schaltet? Das menschliche Gehirn, das als Vorbild diente, besteht ja auch nicht nur aus einer ' +
                        'einzelnen Nervenzelle.', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('custom-image',
                {isNetwork: false, path: 'MLP.png', width: 500}, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie im Gehirn können mehrere Perceptrons zu einem komplexen ' +
                        'Netz zusammengestellt werden. Ein solches Netz aus mehreren Perceptrons heißt Multi Layer ' +
                        'Perceptron (MLP) und besteht grundlegend aus drei Teilen: ', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('info-element',
                {
                    content: '1. Der Input Layer: nimmt die Eingaben (z.B. die Pixel-Werte) entgegen.',
                    color: 'var(--light-blue)'
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('info-element',
                {
                    content: '2. Die Hidden Layer: bilden zusammen jede beliebige Funktion ab. Die Zahl der versteckten ' +
                        'Layer und die Zahl der Knoten in jedem Layer ist nur durch die Ressourcen des Rechners begrenzt.',
                    color: 'var(--orange)'
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('info-element',
                {
                    content: '3. Der Output Layer: liefert in jedem Knoten wieder die Unterscheidung zwischen zwei Klassen. ' +
                        'Es können beliebig viele Kklassen abgebildet werden, indem für jede Klasse ein Knoten genommen wird, ' +
                        'der dann diese Klasse gegen alle anderen abgrenzt.',
                    color: 'var(--light-green)'
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Mit einem MLP können auch Funktionen wie XOR abgebildet werden.', ['p-large'])
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('link-button',
                {
                    text: 'Video MLP',
                    link: 'https://www.youtube.com/watch?v=aircAruvnKk',
                    color: '#A8B764',
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('link-button',
                {
                    text: 'Video Gradient Descent im MLP',
                    link: 'https://www.youtube.com/watch?v=IHZwWFHWa-w',
                    color: '#A8B764',
                }, ''));
            // https://www.youtube.com/watch?v=aircAruvnKk
            // https://www.youtube.com/watch?v=IHZwWFHWa-w
            // https://www.youtube.com/watch?v=Ilg3gGewQ5U

            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn man ein Netz aufbauen möchte, muss man sich für jeden ' +
                        'dieser Layer überlegen, wie viele Knoten (bzw. Schichten) dieser haben muss. Wir werden uns ' +
                        'jetzt überlegen, wie ein Netz aussehen könnte, das ein Bild aus 28x28 Pixeln entgegen nimmt und ' +
                        'zu einer von 10 Klassen zuordnet.', [])
                }, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Was muss man sich überlegen, um ein eigenes Netz zu erstellen?', ['p-large'])
                }, ''));

            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Fangen wir mit dem Input Layer an: wir müssen 28x28 Pixel an ' +
                        'das Netz übergeben, also brauchen wir 784 Knoten im Input Layer.', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Da wir 10 Klassen erkennen wollen, brauchen wir für jede ' +
                        'Klasse einen Knoten im Output Layer. Gibt der erste Knoten dann eine 1 aus und alle anderen ' +
                        'eine 0 wissen wir, dass die Zahl eine 0 war oder das Kleidungsstück ein T-Shirt. Die Zuordnung ' +
                        'geben wir vor, indem wir die Reihenfolge der Klassen in einer Liste festlegen. Das Netz lernt ' +
                        'dann diese Zuordnung durch unsere Label.', [])
                }, ''));
            this.currentChapter.pages[2].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Input- und Output-Schichten werden oft durch das zu lösende ' +
                'Problem vorgegeben.', ['p-large'])
                }, ''));

            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Jetzt kommt der schwierigste Teil: die Entscheidung wie viele ' +
                        'Hidden Layer mit wievielen Knoten wir brauchen.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Hier können wir auf zwei Probleme treffen: ' +
                        'wenn wir zu wenig Knoten nehmen, könnte unser Netz nicht komplex genug sein, um die ' +
                        'Aufgabe zu lernen (dieses Problem heißt Underfitting). Wenn wir aber zu viele Schichten mit zu ' +
                        'vielen Knoten nehmen, kann es passieren, dass unser Netz einfach alle Beispiele auswendig ' +
                        'lernt (Overfitting). Beim Overfitting ist das Netz im Training sehr gut, aber im späteren ' +
                        'Einsatz plötzlich sehr schlecht, wenn es neue Bilder sieht.', [])
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Es gibt hier keine falsche oder richtige Antwort. Wie bei ' +
                'vielen Problemen in der Informatik muss zwischen Leistung und Ressourcenverbrauch abgewogen ' +
                'werden.', ['p-large'])
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('link-button',
                {
                    text: 'Backpropagation im MLP',
                    link: 'https://www.youtube.com/watch?v=Ilg3gGewQ5U',
                    color: '#A8B764',
                }, ''));
        }
    }
</script>

<style scoped>

</style>