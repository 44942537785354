<template>
    <div :class="{buttons: isTwoCol}">
        <CustomButton v-for="(c, index) in content" v-bind:key="index" v-bind:text="c.text"
                      v-bind:is-color="c.isColor"
                      v-bind:color="c.image"
                      v-bind:image="c.image"
                      v-bind:is-dark="c.isDark"
                      v-on:click="clicked(c.text)"/>
    </div>
</template>

<script>
    import CustomButton from "./CustomButton";

    export default {
        name: "button-list",
        components: {
            CustomButton
        },
        emits: ['action'],
        props: {
            content: Array,
            isTwoCol: Boolean,
            actionType: String,
            method: { type: Function },
        },
        methods: {
            clicked: function (i){
                if (this.actionType === 'choose-label'){
                    this.$emit('action', i);
                } else {
                    this.$emit('action', this.actionType);
                }
            }
        }
    }
</script>

<style scoped>
    .buttons {
        margin: 1.7em;
        display: grid;
        grid-template-columns: min-content min-content;
        grid-gap: 10px;
    }
</style>