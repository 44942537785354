export default class Dataset {
    _train_url = '';
    _class_url = '';
    _test_url = '';
    _net_url = '';
    _classList = [];
    _colorList = [];


    constructor(train_url, class_url, net_url, test_url, classList, colorList) {
        this._train_url = train_url;
        this._class_url = class_url;
        this._test_url = test_url;
        this._net_url = net_url;
        this._classList = classList;
        this._colorList = colorList;
    }

    get class_url() {
        return this._class_url;
    }

    set class_url(value) {
        this._class_url = value;
    }

    get classList() {
        return this._classList;
    }

    set classList(value) {
        this._classList = value;
    }


    get colorList() {
        return this._colorList;
    }

    set colorList(value) {
        this._colorList = value;
    }
}