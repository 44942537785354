<template>
        <div class="main">
            <CustomButton v-if="getPrevText() !== ''" class="arrow arrow-l" :text="getPrevText()"
                          v-bind:is-color="false"
                          image="keyboard_arrow_left_black_24dp.svg"
                          v-bind:is-dark="false"
                          v-on:click="prevPage()"></CustomButton>
            <div class="chapter-title">
                <h2>{{chapter.number}}</h2>
                <h2>{{chapter.title}}</h2>
            </div>
            <h3 class="page-title">{{chapter.number}}.{{chapter.pages[currentPage].number}}
                {{chapter.pages[currentPage].title}}</h3>
            <div class="page-content onecol">
                <component :is="createComponent(c.component)" v-bind="c.props"
                           v-for="(c, index) in chapter.pages[currentPage].content"
                           v-bind:key="index" @action="componentAction"></component>
            </div>
            <CustomButton v-if="getNextText() !== ''" class="arrow arrow-r" :text="getNextText()"
                          v-bind:is-color="false"
                          image="keyboard_arrow_right_black_24dp.svg"
                          v-bind:is-dark="false"
                          v-bind:icon-right="true"
                          v-on:click="nextPage()"></CustomButton>
        </div>
        <div class="sidebar r">
            <div class="chapter-summary">
                <!--<p>{{chapter.pages[currentPage].summary}}</p>-->
                <component :is="createComponent(c.component)" v-bind="c.props"
                           v-for="(c, index) in chapter.pages[currentPage].summary"
                           v-bind:key="index"></component>
            </div>
        </div>
</template>

<script>
    import CustomButton from '../CustomButton.vue';
    import TextElement from '../TextElement.vue';
    import CustomImage from '../CustomImage.vue';
    import ButtonList from '../ButtonList.vue';
    import IconList from '../IconList.vue';
    import PixelImage from '../PixelImage.vue';
    import ClassificationGame from '../ClassificationGame.vue';
    import GradientDescentGame from '../GradientDescentGame.vue';
    import ImageGrid from '../ImageGrid.vue';
    import PixelImageGrid from '../PixelImageGrid.vue';
    import Perceptron from '../Perceptron.vue';
    import ExpandableInfoElement from '../ExpandableInfoElement.vue';
    import CodeElement from '../CodeElement.vue';
    import LinkButton from '../LinkButton.vue';
    import TwoColumnContent from './TwoColumnContent.vue';

    export default {
        name: "SingleSidebarChapter",
        components: {
            CustomButton, // eslint-disable-line vue/no-unused-components
        },
        props: {
            currentChapter: Object,
            prev: String,
            next: String,
            prevTitle: String,
            nextTitle: String
        },
        watch: { // need to watch the route to know when sidenav is changing the route
            $route() {
                this.currentPage = parseInt(this.$route.params.page.substring(1) - 1);
            }
        },
        data() {
            return {
                currentPage: 0, // starts with 0
                dataset: 'digit',
                chapter: {},
                isMobile: false,
                window: {
                    width: 0,
                    height: 0
                }
            }
        },
        created() {
            this.chapter = this.currentChapter.currentChapter;
            // console.log(this.chapter);
            this.dataset = this.$store.state.dataset;
            this.currentPage = parseInt(this.$route.params.page.substring(1) - 1);
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        mounted() {
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            createComponent(component) {
                switch (component) {
                    case 'text-element':
                        return TextElement;
                    case 'custom-image':
                        return CustomImage;
                    case 'button-list':
                        return ButtonList;
                    case 'pixel-image':
                        return PixelImage;
                    case 'icon-list':
                        return IconList;
                    case 'classification-game':
                        return ClassificationGame;
                    case 'gradient-descent-game':
                        return GradientDescentGame;
                    case 'image-grid':
                        return ImageGrid;
                    case 'perceptron':
                        return Perceptron;
                    case 'two-col-layout':
                        return TwoColumnContent;
                    case 'pixel-grid':
                        return PixelImageGrid;
                    case 'info-element':
                        return ExpandableInfoElement;
                    case 'code-element':
                        return CodeElement;
                    case 'link-button':
                        return LinkButton;
                }
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
                this.isMobile = this.window.width < 1400;

            },
            nextPage: function () {
                if (this.currentPage < this.chapter.pages.length - 1) {
                    this.currentPage += 1;
                    let route = this.chapter.pages[this.currentPage].link; // pages start with 1
                    this.$router.push(route);

                } else {
                    this.nextChapter();
                }
            },
            prevPage: function () {
                if (this.currentPage > 0) {
                    this.currentPage -= 1;
                    let route = this.chapter.pages[this.currentPage].link; // pages start with 1
                    this.$router.push(route);

                } else {
                    this.prevChapter();
                }
            },
            nextChapter: function () {
                this.$router.push(this.next);
            },
            prevChapter: function () {
                this.$router.push(this.prev);
            },
            getNextText() {
                if (this.currentPage < this.chapter.pages.length - 1) {
                    return this.chapter.pages[(this.currentPage + 1)].title;
                } else {
                    return this.nextTitle;
                }
            },
            getPrevText() {
                if (this.currentPage > 0) {
                    return this.chapter.pages[(this.currentPage - 1)].title;
                } else {
                    return this.prevTitle;
                }
            },
            componentAction: function (compAction){
                console.log('component action: ' + compAction);
                switch (compAction) {
                    case 'choose-fashion':
                        this.$store.commit('setDataset', 'fashion');
                        this.$router.push('/c1');
                        break;
                    case 'choose-digit':
                        this.$store.commit('setDataset', 'digit');
                        this.$router.push('/c1');
                        break;
                    case 'open-link':
                        window.open('http://example.com');
                        break;
                }
            },
            linkAction: function (link){
                window.open(link);
            }
        }
    }
</script>

<style scoped>
    .sidebar {
        width: 100%;
        background-color: var(--text-dark);
        color: var(--text-light);
        display: grid;
        grid-template-rows: min-content min-content;
        align-content: space-between;
        justify-items: left;
        padding-top: 3rem;
    }

    .main {
        display: grid;
        justify-items: center;
        align-items: start;
        grid-gap: 15px;
        overflow-y: auto;
    }

    .chapter-title {
        padding: 0.5rem 3rem 0 3rem;
        grid-area: chapter-title;
        align-self: start;
        justify-self: left;
        display: grid;
        grid-template-columns: min-content auto;
        color: var(--text-dark-70);
    }

    .chapter-title h2 {
        margin: 0 0.6em 0 0;
        line-height: 1.1em;
    }

    .page-title {
        padding: 0 3rem;
        grid-area: page-title;
        align-self: start;
        justify-self: left;
        margin: 0;
    }

    .page-content {
        grid-area: page-content;
        justify-items: left;
        width: 80%;
        height: 100%;
    }

    .onecol {
        margin-top: 1em;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: min-content;
        grid-gap: 10px;
    }


    @media only screen and (max-width: 1400px) {
        .prev-pages, .next-pages {
            display: none;
        }

        .chapter-summary p {
            font-size: clamp(0.9em, 1vw, 1.1em);
            line-height: 1.2em;
        }

        .r {
            padding: 0;
        }

        .main {
            padding: 0;
            grid-template-areas: 'arrow-l' 'chapter-title' 'page-title' 'page-content'  'arrow-r';
            grid-template-columns: auto;
            grid-template-rows: min-content min-content min-content auto min-content;
        }

        .mobile {
            grid-area: mobile;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .arrow-l, .arrow-r {
            margin: 1em 2em;
        }
    }

    @media only screen and (min-width: 1400px) {
        .main {
            grid-template-areas: 'chapter-title chapter-title' 'page-title page-title' 'page-content page-content' 'arrow-l arrow-r';
            grid-template-columns: auto auto;
            grid-template-rows: min-content min-content auto auto;
            padding-top: 2em;
        }
    }

    .chapter-summary {
        font-family: Gaegu, Arial, sans-serif;
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .chapter-summary p {
        font-size: clamp(1.3em, 2vw, 1.6em);
    }

    .pages {
        grid-area: pages;
        margin-bottom: 1em;
        display: flex;
        align-self: center;
        margin-top: auto;
    }

    .page-dot {
        border-radius: 50%;
        width: 1em;
        height: 1em;
        margin: 10px;
    }

    .page-dot:hover {
        background-color: var(--light-blue);
        cursor: pointer;
    }

    .prev-pages, .next-pages {
        padding: clamp(1em, 5vw, 3em);
    }

    .prev-pages p {
        color: var(--light-green);
    }

    .prev-pages p:hover, .next-pages p:hover {
        color: var(--light-blue);
        cursor: pointer;
    }

    .arrow:hover {
        cursor: pointer;
    }

    .arrow-l {
        grid-area: arrow-l;
        justify-self: start;
        align-self: end;
        margin: 2em;
    }

    .arrow-r {
        grid-area: arrow-r;
        justify-self: end;
        align-self: end;
        margin: 2em;
    }


</style>