<template>
    <div class="code-container shadow dark" :style="codeRows">
        <p class="m-0 p-light" v-for="(r, index) of content" v-bind:key="index">{{index + 1}}</p>
        <p class="code m-0" v-for="(r, index) of content" v-bind:key="index">{{r}}</p>
    </div>
    
</template>

<script>
    export default {
        name: "code-element",
        props: {
            content: Array,
            color: String
        },
        computed: {
            codeRows() {
                return {
                    gridTemplateRows: `repeat(${this.content.length}, min-content)`,
                    backgroundColor: `${this.color}`
                }
            }
        }
    }
</script>

<style scoped>
    .code-container {
        margin: 20px 0;
        width: 100%;
        padding: 1em;
        border-radius: 0.5em;
        display: grid;
        grid-template-columns: min-content auto;
        grid-auto-flow: column;
        column-gap: 20px;
        align-items: start;
        line-height: 1em;
    }

    .p-light{
        color: var(--text-light-50);
        padding-right: 0.7em;
        border-right: 1px solid var(--text-light-50)
    }

    .code {
        white-space: pre;
    }
</style>