<template>
    <div class="twocol">
        <div class="top-col">
            <component :is="createComponent(c.component)" v-bind="c.props"
                       v-for="(c, index) in topCol"
                       v-bind:key="index" @action="componentAction"></component>
        </div>
        <div class="left-col p-center">
            <component :is="createComponent(c.component)" v-bind="c.props"
                       v-for="(c, index) in leftCol"
                       v-bind:key="index" @action="componentAction"></component>
        </div>
        <div class="right-col p-center">
            <component :is="createComponent(c.component)" v-bind="c.props"
                       v-for="(c, index) in rightCol"
                       v-bind:key="index" @action="componentAction"></component>
        </div>
        <div class="bot-col">
            <component :is="createComponent(c.component)" v-bind="c.props"
                       v-for="(c, index) in botCol"
                       v-bind:key="index" @action="componentAction"></component>
        </div>
    </div>
</template>

<script>
    import TextElement from '../TextElement.vue';
    import CustomImage from '../CustomImage.vue';
    import ButtonList from '../ButtonList.vue';
    import IconList from '../IconList.vue';
    import PixelImage from '../PixelImage.vue';
    import ClassificationGame from '../ClassificationGame.vue';
    import GradientDescentGame from '../GradientDescentGame.vue';
    import ImageGrid from '../ImageGrid.vue';
    import Perceptron from '../Perceptron.vue';

    export default {
        name: "two-col-layout",
        props: {
            topCol: Array,
            leftCol: Array,
            rightCol: Array,
            botCol: Array,
        },
        methods: {
            createComponent(component) {
                switch (component) {
                    case 'text-element':
                        return TextElement;
                    case 'custom-image':
                        return CustomImage;
                    case 'button-list':
                        return ButtonList;
                    case 'pixel-image':
                        return PixelImage;
                    case 'icon-list':
                        return IconList;
                    case 'classification-game':
                        return ClassificationGame;
                    case 'gradient-descent-game':
                        return GradientDescentGame;
                    case 'image-grid':
                        return ImageGrid;
                    case 'perceptron':
                        return Perceptron;
                }
            },
            componentAction: function (compAction){
                console.log('component action: ' + compAction);
                switch (compAction) {
                    case 'choose-fashion':
                        this.$store.commit('setDataset', 'fashion');
                        this.$router.push('/c1');
                        break;
                    case 'choose-digit':
                        this.$store.commit('setDataset', 'digit');
                        this.$router.push('/c1');
                        break;
                    case 'start-training':
                        // todo: start training
                        break;
                    case 'start-test':
                        // todo: start test
                        break;
                }
            }
        }
    }
</script>

<style scoped>
    .twocol {
        display: grid;
        width: 100%;
        height: 100%;
    }

    .top-col {
        grid-area: top-col;
        display: grid;
        justify-items: left;
    }

    .bot-col {
        grid-area: bot-col;
        display: grid;
        justify-items: left;
    }

    .left-col {
        grid-area: left-col;
        display: grid;
        justify-items: center;
        align-items: start;
    }

    .right-col {
        grid-area: right-col;
        display: grid;
        justify-items: center;
        align-items: start;
    }

    @media only screen and (max-width: 1000px) {
        .twocol {
            grid-template-columns: auto;
            grid-template-areas: "top-col" "left-col" "right-col" "bot-col";
            grid-gap: 5em;
        }
    }

    @media only screen and (min-width: 1000px) {
        .twocol {
            grid-template-columns: auto auto;
            grid-template-areas: "top-col top-col" "left-col right-col" "bot-col bot-col";
            column-gap: 20px;
        }
    }
</style>