<template>
    <!-- full page navigation -->
    <!--<div class="full-page-menu" v-if="navOpen">
        <img class="menu-icon" src="../public/close_green_24dp.svg" alt="menu" v-on:click="closeMenu()"/>
        <div class="menu-content" :style="gridStyle">
            <div class="menu-item" v-for="(c, ic) in chapters" v-bind:key="ic">
                <h1>{{c.number}}</h1>
                <h3 v-on:click="goTo(c.link, ic)">{{c.title}}</h3>
                <div class="sub-item" v-for="(s, is) in c.pages" v-bind:key="is">
                    <img src="../public/arrow_right_green_24dp.svg" alt="menu"/>
                    <p v-on:click="goTo(s.link, ic)"><b>{{c.number}}.{{s.number}}</b> {{s.title}}</p>
                </div>
            </div>
        </div>
    </div>-->
    <!-- main template for pages -->
    <section>
        <!--<img class="menu-icon shadow accent" src="../public/menu_black_24dp.svg" alt="menu" v-on:click="openMenu()"/>-->
        <!-- content of pages -->
        <router-view v-if="isMobile"></router-view>
        <div class="navbar">
            <div v-if="!isMobile" class="thread shadow"></div>
            <div class="nav-chapter" v-for="(c, ic) in chapters" v-bind:key="ic">
                <div class="chap-number shadow" v-on:click="goTo(c.link + '/:1', ic)">
                    <h1 :class="{cactive: ic === currentChapterIndex}">{{c.number}}</h1>
                </div>
                <h2 v-if="!isMobile" class="chap-title" v-on:click="goTo(c.link + '/:1', ic)">{{c.title}}</h2>
                <div v-if="!isMobile" class="sub-nav" :class="{cexpanded: ic === currentChapterIndex}">
                    <div class="nav-page" v-for="(sc, isc) in c.pages" v-bind:key="isc" v-on:click="goTo(sc.link, ic)">
                        <p class="page-number shadow p-small"
                           :class="{cactive: sc.link === currentRoute}">.{{sc.number}}</p>
                        <p class="page-title" :class="{pactive: sc.link === currentRoute}">{{sc.title}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- content of pages -->
        <router-view v-if="!isMobile"></router-view>
    </section>
</template>

<script>
    import content from "./assets/ChapterList.json";

    export default {
        name: 'App',
        data() {
            return {
                navOpen: false,
                gridColumns: 1,
                window: {
                    width: 0,
                    height: 0
                },
                chapters: [],
                currentChapterIndex: 0,
                currentRoute: '/c0/:1',
                isMobile: false
            }
        },watch: { // need to watch the route to know when buttons are changing the route
            $route() {
                this.currentRoute = this.$route.path; // for setting active color
                this.currentChapterIndex = parseInt(this.currentRoute.split('/')[1].substring(1)); // expand current chapter
            }
        },
        computed: {
            gridStyle() {
                return {
                    gridTemplateColumns: `repeat(${this.gridColumns}, 1fr)`
                }
            },
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
            this.chapters = content.chapters;
        },
        mounted() {
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            goTo: function (path, index) {
                // this.closeMenu();
                this.$router.push(path);
                this.currentRoute = this.$route.path; // for setting active color
                this.currentChapterIndex = index; // for expanding the menu
            },
            openMenu: function () {
                this.navOpen = true;
                // clip-path: circle(100%);
            },
            closeMenu: function () {
                this.navOpen = false;
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
                this.calcGridCount();
                this.isMobile = this.window.width < 1400;

            },
            calcGridCount() {
                let size = Math.round((60 * this.window.width) / 100); // calc size for main without sidebar
                const count = Math.round(size / 400); // calc count of image boxes with 190px + grid padding that fit into size
                if (count >= this.chapters.length) {
                    this.gridColumns = this.chapters.length;
                } else {
                    this.gridColumns = count;
                }
            },
        }
    }
</script>
<style scoped>
    /* code for fullscreen menu ------------------------------ */

    .full-page-menu {
        position: fixed;
        z-index: 10;
        margin: 0;
        width: 100%;
        height: 100%;
        background-color: var(--text-dark);
        color: var(--light-green);
        text-align: left;
        overflow-y: scroll;
    }

    .menu-content {
        padding: clamp(1em, 10vh, 5em) clamp(3em, 10vw, 15em);
        display: grid;
        grid-gap: 3em;
        width: auto;
        height: auto;
        justify-content: center;
        align-content: start;
    }

    .menu-item {
        min-width: 350px;
    }

    .menu-item h3 {
        font-family: SpecialElite, Arial, sans-serif;
    }

    .menu-item h4, .menu-item p {
        cursor: pointer;
    }

    .sub-item {
        display: grid;
        grid-template-columns: 30px auto;
        align-items: center;
        justify-items: flex-start;
        color: var(--text-light);
    }

    .sub-item p {
        margin: 0.5em;
        font-size: clamp(0.9em, 1vw, 1em);
        line-height: 1em;
        text-align: left;
    }

    .menu-icon {
        padding: 0.5em;
        border-radius: 50%;
        position: fixed;
        top: 1em;
        left: 1em;
        width: 40px;
        height: 40px;
    }

    /* code for navbar ------------------------------ */
   .thread {
        width: 6px;
        z-index: -1;
        left: 2.5em;
        top: 2em;
        bottom: 2em;
        position: absolute;
        background-color: var(--bg-dark);
        border-radius: 4px;
    }



    .cactive {
        color: var(--orange) !important;
    }

    .chap-number {
        width: 40px;
        height: 40px;
        background-color: var(--text-dark);
        color: var(--text-light);
        border-radius: 50%;
        padding: 0.5em;
        margin: 0.9em;
        display: grid;
        justify-items: center;
        align-items: center;
        cursor: pointer;
    }

    .chap-number h1 {
        font-size: clamp(1.4em, 2vw, 1.8em);
        margin: 0.35em 0;
    }

    .chap-number:hover {
        color: var(--light-blue);
    }

    .chap-title {
        line-height: 1em;
        margin: 0 0.5em;
        cursor: pointer;
    }

    .sub-nav {
        grid-column: 1 / span 2;
        max-height: 0;
        display: none;
        transition: 0.2s ease-in-out;
    }

    .cexpanded {
        max-height: 100%;
        display: block;
    }

    .nav-page {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        padding-left: 1.3em;
        background-color: var(--bg-dark-10);
        cursor: pointer;
    }

    .page-number {
        width: 1em;
        height: 1em;
        color: var(--text-light);
        background-color: var(--text-dark);
        border-radius: 50%;
        padding: 0.5em;
        margin: 0.5em;
        justify-self: center;
        text-align: center;
        vertical-align: center;
        line-height: 1em;
    }

    .page-number:hover {
        color: var(--light-blue);
    }

    .page-title {
        margin: 0 0.5em;
        font-size: clamp(0.9em, 1vw, 1em);
        line-height: 1em;
    }

    .pactive {
        color: var(--orange);
        background-color: var(--text-dark);
        border-radius: 6px;
        padding: 0.5em;
    }

    @media only screen and (max-width: 1400px) {
        .navbar {
            width: 100vw;
            height: min-content;
            overflow-y: hidden;
            overflow-x: auto;
            display: flex;
            justify-content: space-evenly;
            align-content: center;
        }

        .nav-chapter {
            width: min-content;
            height: min-content;
            margin: 5px;
        }
    }

    @media only screen and (min-width: 1400px) {
        .navbar {
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;
            display: grid;
            justify-items: stretch;
            position: relative;
            background-color: var(--bg-dark-5);
        }

        .nav-chapter {
            display: grid;
            grid-template-columns: min-content auto;
            align-items: center;
            margin: 0.5em 1em 0.5em 0;
        }
    }

</style>
<style>
    :root {
        --bg-light: #E9E0CF;
        --bg-dark: #4c4040;
        --text-dark-70: rgba(83, 71, 71, 0.7);
        --bg-dark-10: rgba(76, 64, 64, 0.1);
        --bg-dark-5: rgba(76, 64, 64, 0.05);
        --text-dark: #534747;
        --text-light: #D5BE95;
        --text-light-50: rgba(213, 190, 149, 0.5);
        --red: #EA655E;
        --pink: #C86E90;
        --purple: #946DAE;
        --dark-blue: #667BA5;
        --light-blue: #7ABBB7;
        --dark-green: #66A464;
        --light-green: #A8B764;
        --yellow: #D2BA5A;
        --orange: #D6A15C;
    }

    @font-face {
        font-family: SourceCodePro;
        src: url(assets/fonts/SourceCodePro-Regular.ttf);
    }

    @font-face {
        font-family: Gaegu;
        src: url(assets/fonts/Gaegu-Regular.ttf);
    }

    @font-face {
        font-family: SpecialElite;
        src: url(assets/fonts/SpecialElite-Regular.ttf);
    }

    body {
        padding: 0;
        background-color: var(--bg-light);
        margin: 0;
    }

    .m-5 {
        margin: 5px;
    }

    .m-0 {
        margin: 0;
    }

    #app {
        font-family: SourceCodePro, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--text-dark);
        text-align: left;
        margin: 0;
    }

    .p-center {
        text-align: center;
    }

    h1 {
        font-family: SpecialElite, Arial, sans-serif;
        font-size: clamp(1.8em, 2vw, 2.2em);
        /*text-shadow: 5px 5px 15px rgba(83,71,71, 0.4);*/
        line-height: 1em;
    }

    h2 {
        font-family: SpecialElite, Arial, sans-serif;
        font-size: clamp(1.2em, 2vw, 1.4em);
        max-width: 80vw;
        line-height: 2em;
    }

    h3 {
        font-size: clamp(1.3em, 2vw, 1.6em);
        max-width: 80vw;
        line-height: 2em;
    }

    h4 {
        font-size: clamp(1.1em, 1vw, 1.4em);
        line-height: 2em;
    }

    p {
        font-size: clamp(1em, 1vw, 1.2em);
        line-height: 2em;
    }

    .p-small {
        font-size: clamp(0.8em, 1vw, 0.9em);
    }

    .p-large {
        font-size: clamp(1.4em, 2vw, 1.6em);
    }

    .p-blue {
        color: var(--light-blue);
    }

    .p-green {
        color: var(--light-green);
    }

    .p-orange {
        color: var(--orange);
    }

    .italic {
        font-style: italic;
    }

    .text-left {
        text-align: start;
    }

    section {
        width: 100vw;
        height: 100vh;
        display: grid;
    }


    .max-width-80 {
        max-width: 80%;
    }

    .dark {
        background-color: var(--text-dark);
        color: var(--text-light);
    }

    .accent {
        background-color: var(--light-blue);
        color: var(--text-dark);
    }

    .image {
        border-radius: .2em;
        width: 150px;
        height: 150px;
        margin: 20px;
    }

    .blue {
        background-color: var(--light-blue);
        color: var(--text-dark);
    }

    .green {
        background-color: var(--light-green);
        color: var(--text-dark);
    }

    .orange {
        background-color: var(--orange);
        color: var(--text-dark);
    }

    .red {
        background-color: var(--red);
        color: var(--text-dark);
    }

    .shadow {
        box-shadow: rgba(83, 71, 71, 0.4) 0px 5px 15px 0px;
    }

    @media only screen and (max-width: 1400px) {
        section {
            grid-template-rows: auto min-content min-content;
        }
        body {
            scrollbar-color: var(--light-blue) var(--bg-dark-10);
        }

        /* width */
        body::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        body::-webkit-scrollbar-track {
            background-color: var(--bg-dark-10);
        }

        /* Handle */
        body::-webkit-scrollbar-thumb {
            background-color: var(--light-blue);
            border-radius: 10px;
        }
    }

    @media only screen and (min-width: 1400px) {
        body {
            scrollbar-color: var(--light-blue) var(--bg-dark-10);
        }

        /* width */
        body::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        body::-webkit-scrollbar-track {
            background-color: var(--bg-dark-10);
        }

        /* Handle */
        body::-webkit-scrollbar-thumb {
            background-color: var(--light-blue);
            border-radius: 10px;
        }

        section {
            grid-template-columns: min-content auto 20vw;
        }

        h1 {
            max-width: 50vw;
        }

    }


</style>
