<template>
    <div class="results-grid" :style="gridStyle">
        <div class="center-grid" v-for="(p, index) in content" v-bind:key="index">
            <PixelImage v-bind:content="p"></PixelImage>
            <div v-if="showLabel" class="shadow dark label">
                <h4 class="m-5">{{label[index]}}</h4>
            </div>
        </div>
    </div>
</template>

<script>
    import PixelImage from "./PixelImage";
    export default {
        name: "pixel-grid",
        components: {PixelImage},
        props:{
            content: Array,
            showLabel: Boolean,
            label: Array
        },
        data(){
            return {
                gridColumns: 1,
                window: {
                    width: 0,
                    height: 0
                },
            }
        },
        computed: {
            gridStyle() {
                return {
                    gridTemplateColumns: `repeat(${this.gridColumns}, 1fr)`
                }
            },
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
                this.calcGridCount();
            },
            calcGridCount() {
                let size = 0;
                if (this.window.width > 1000) {
                    size = Math.round((60 * this.window.width) / 100); // calc size for main without sidebar
                } else {
                    size = this.window.width;
                }
                const count = Math.round(size / 220); // calc count of image boxes with 190px + grid padding that fit into size
                if (count >= this.content.length) {
                    this.gridColumns = this.content.length;
                } else {
                    this.gridColumns = count;
                }
            },
        }
    }
</script>

<style scoped>
    .results-grid {
        width: 100%;
        display: grid;
        grid-gap: 10px;
        justify-content: center;
    }

    .center-grid {
        display: grid;
        justify-items: center;
        align-items: center;
    }

    .label {
        border-radius: 0.5em;
        margin: 10px;
        padding: 0.7em;
        white-space: nowrap;
        display: flex;
    }
</style>