export default class MapItemData {
    _tags = [];
    _title = '';
    _short = '';
    _text = '';
    _urls = [];

    constructor(tags, title, short, text, urls) {
        this._tags = tags;
        this._title = title;
        this._short = short;
        this._text = text;
        this._urls = urls;
    }


    get tags() {
        return this._tags;
    }

    set tags(value) {
        this._tags = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    get short() {
        return this._short;
    }

    set short(value) {
        this._short = value;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get urls() {
        return this._urls;
    }

    set urls(value) {
        this._urls = value;
    }
}