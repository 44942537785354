<template>
    <svg width="100%" height="100%" v-if="elementsReady" class="arrow-svg">
        <defs>
            <marker id="markerArrow" markerWidth="20" markerHeight="20" orient="auto">
                <path d="M11.71 15.29l2.59-2.59c.39-.39.39-1.02 0-1.41L11.71 8.7c-.63-.62-1.71-.18-1.71.71v5.17c0 .9 1.08 1.34 1.71.71z"
                      :style="arrowColor"></path>
            </marker>
        </defs>

        <line :x1="xStart" :y1="yStart" :x2="xEnd" :y2="yEnd" class="arrow"></line>
    </svg>
</template>

<script>
    export default {
        name: "ArrowLine",
        props: {
            startID: String,
            endID: String,
            color: String
        },
        data() {
            return {
                xStart: 0,
                yStart: 0,
                xEnd: 0,
                yEnd: 0,
                elementsReady: false
            }
        },
        computed: {
            arrowColor() {
                return {
                    fill: `${this.color}`
                }
            },
        },
        created() {
            window.addEventListener('resize', this.handleResize);
        },
        mounted() {
            // wait for elements to be inserted into DOM
            this.$nextTick(this.drawArrow());
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            drawArrow: function () {
                try{
                    let startElement = document.getElementById(this.startID);
                    let endElement = document.getElementById(this.endID);
                    let startRect = startElement.getBoundingClientRect();
                    let endRect = endElement.getBoundingClientRect();
                    this.xStart = parseInt(startRect.right);
                    this.yStart = parseInt(startRect.top + (startRect.height / 2));
                    this.xEnd = parseInt(endRect.left);
                    this.yEnd = parseInt(endRect.top + (endRect.height / 2));
                    // console.log(this.xStart + ' ' + this.yStart + ' ' + this.xEnd + ' ' + this.yEnd);
                    this.elementsReady = true;
                }catch{
                    this.elementsReady = false;
                    console.log('arrows did not find elements to point at!');
                }
            },
            handleResize() {
                this.drawArrow();
            },
        }
    }
</script>

<style scoped>
    .arrow-svg {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index: -1;
    }
    .arrow {
        stroke: var(--bg-dark);
        stroke-width: 2px;
        marker-end: url(#markerArrow);
    }
</style>