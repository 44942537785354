<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          :prev="chapters[5].link + '/:' + chapters[5].pages.length"
                          :next="chapters[7].link" :prevTitle="chapters[5].title"
                          :nextTitle="chapters[7].title"></SingleSidebarChapter>
</template>

<script>
    import SingleSidebarChapter from '../components/layouts/SingleSidebarChapter.vue'
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import content from "../assets/ChapterList.json";
    // import ButtonListContent from "../models/ButtonListContent";

    export default {
        name: "Chapter6",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                chapters: [],
                currentChapter: {},
                dataset: ''
            }
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[6];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
                this.currentChapter.pages[i].summary = [];
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Dieser Abschnitt soll vor allem zeigen, wie ' +
                        'wenig Zeilen Code notwendig sind, um heutzutage ein Künstliches Neuronales Netz zu programmieren. ' +
                        'Es ist nicht schlimm, wenn Du nicht genau verstehst, was der Code macht oder wie man Code liest.', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Dass man gar nicht hunderte Codezeilen für ein Netz selber ' +
                        'schreiben muss, liegt daran, dass die ganzen Algorithmen, die man braucht (z.B. der ' +
                        'Backpropagation-Algorithmus) schon von anderen Programmierern in sogenannten Frameworks ' +
                        'vorbereitet wurden. ' +
                        'Es gibt zwei sehr beliebte Frameworks für das Programmieren von Netzen: TensorFlow und PyTorch. ' +
                        'Wir werden TensorFlow verwenden. Dafür importieren wir als erstes das Framework in unserem Code: ', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('code-element',
                {
                    content: ['import tensorflow as tf']
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Hinweis: Python und TensorFlow müssen auf dem Computer ' +
                'installiert sein.', ['p-large'])
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('link-button',
                {
                    text: 'TensorFlow Klassifikation Beispiel',
                    link: 'https://www.tensorflow.org/tutorials/keras/classification',
                    color: '#A8B764',
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('link-button',
                {
                    text: 'PyTorch Klassifikation Beispiel',
                    link: ' https://pytorch.org/tutorials/beginner/blitz/cifar10_tutorial.html',
                    color: '#A8B764',
                }, ''));
            // https://www.tensorflow.org/tutorials/keras/classification
            // https://pytorch.org/tutorials/beginner/blitz/cifar10_tutorial.html

            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Als nächstes besorgen wir uns unsere Bilder, die wir unserem ' +
                        'Netz für das Training zeigen wollen. TensorFlow bietet eine Methode an, mit der wir die Bilder ' +
                        'herunterladen und als Listen aus Pixeln speichern können: ', [])
                }, ''));
            if (this.dataset === 'fashion') {
                this.currentChapter.pages[1].content.push(new CustomComponent('code-element',
                    {
                        content: [
                            'dataset = tf.keras.datasets.fashion_mnist',
                            '(train_images, train_labels), ',
                            '    (test_images, test_labels) = dataset.load_data()']
                    }, ''));
            } else {
                this.currentChapter.pages[1].content.push(new CustomComponent('code-element',
                    {
                        content: [
                            'dataset = tf.keras.datasets.mnist',
                            '(train_images, train_labels), ',
                            '    (test_images, test_labels) = dataset.load_data()']
                    }, ''));
            }
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'In der ersten Zeile geben wir an, welches Datenset wir laden ' +
                        'möchten.', [])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die zweite und dritte Zeile gehören zusammen und schreiben uns vier Listen: eine ' +
                        'Liste mit den Bildern für das Training und eine mit den zugehörigen Labels für jedes Bild. ' +
                        'Dasselbe noch einmal für die Bilder, die wir später verwenden, um zu prüfen, wie gut unser Netz ' +
                        'gelernt hat.', [])
                }, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Es gibt viele bekannte Datensets, die von Forschern für den ' +
                'Vergleich und die Überprüfung von Ergebnissen verwendet werden.', ['p-large'])
                }, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('link-button',
                {
                    text: 'Übersicht über Datasets',
                    link: 'https://paperswithcode.com/datasets',
                    color: '#A8B764',
                }, ''));
            // https://paperswithcode.com/datasets

            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Bilder, die wir verwenden hast Du in den ersten Kapiteln ' +
                        'schon gesehen. Sie sind 28x28 Pixel groß. Die Werte der Pixel sind jetzt allerdings nicht mehr ' +
                        '0 oder 1, sondern in Graustufen zwischen 0 und 255. ', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('custom-image', {isNetwork: true}, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Unsere Listen enthalten also für jedes Bild 28x28 = 784 Werte ' +
                        'zwischen 0 und 255. Mit diesen Zahlen kann ein Netz aber ' +
                        'schlecht rechnen, da solche großen Zahlen Probleme bringen können. Wenn wir den Fehler ' +
                        'berechnen, quadrieren wir zum Beispiel Zahlen. Dabei können solche großen Zahlen schnell zu groß ' +
                        'werden, um sie mit einem Computer darstellen zu können.', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Darum rechnen wir die Werte der Pixel einfach so um, dass sie ' +
                        'zwischen 0 und 1 liegen. Dann kann unser Netz gut damit rechnen.', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('code-element',
                {
                    content: [
                        'train_images = train_images / 255.0',
                        'test_images = test_images / 255.0']
                }, ''));
            this.currentChapter.pages[2].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Hinweis: Python und TensorFlow müssen auf dem Computer ' +
                'installiert sein.', ['p-large'])
                }, ''));

            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Jetzt programmieren wir endlich unser Netz! Dazu nehmen wir ' +
                        'wieder eine Methode, die TensorFlow für uns vorbereitet hat.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('code-element',
                {
                    content: [
                        'model = tf.keras.Sequential([',
                        '   tf.keras.layers.Flatten(input_shape=(28, 28)),',
                        '   tf.keras.layers.Dense(128, activation="relu"),',
                        '   tf.keras.layers.Dense(10)']
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'In der ersten Zeile speichern wir unser Netz in einer ' +
                        'Variable namens "model".', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Dann geben wir an, wie der erste Layer aussehen soll. Dazu ' +
                        'nehmen wir die 28x28 Pixel von unseren Bildern und machen sie platt (flatten), sodass 784 ' +
                        'Zahlen untereinander stehen.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'In der dritten Zeile legen wir dann fest, dass wir einen Hidden ' +
                        'Layer mit 128 Knoten haben.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Und zum Schluss erstellen wir die 10 Knoten des Output Layers ' +
                        'für unsere 10 Klassen.', [])
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Den Aufbau eines Netzes kann man festlegen, indem man die Anzahl der ' +
                'Schichten und Knoten und der Verbindungen zwischen den Schichten festlegt.', ['p-large'])
                }, ''));

            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wir haben zwar den Aufbau für unser Netz festgelegt, aber ' +
                        'noch nicht, wie wir es trainieren wollen. Dazu müssen wir angeben, wie wir den Fehler zwischen ' +
                        'der Ausgabe und der richtigen Antwort berechnen wollen und mit welchem Algorithmus wir das ' +
                        'tiefste Tal finden wollen.', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('code-element',
                {
                    content: [
                        'model.compile(optimizer="adam"',
                        '   loss=tf.keras.losses.SparseCategoricalCrossentropy(from_logits=True),',
                        '   metrics=["accuracy"])']
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Der Optimizer gibt den Algorithmus an, der dafür verwendet ' +
                        'wird, den Fehler über alle Bilder zu minimieren.', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Für die Loss-Funktion, also wie groß der Fehler der aktuellen ' +
                        'Antwort ist, nehmen wir diesmal nicht den Mean Squared Error, sondern die Sparse Categorical ' +
                        'Crossentropy. Welche Funktion hier am besten funktioniert, kann nicht immer vorausgesagt ' +
                        'werden und wird daher oft einfach durch Probieren ermittelt.', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'In der letzten Zeile geben wir an, welchen Wert wir während ' +
                        'des Trainings ausgegeben bekommen, um den Fortschritt zu sehen. In dem Fall ist es die ' +
                        'Genauigkeit, mit der das Netz richtig rät. Diese sollte sich über die Zeit verbessern. ', [])
                }, ''));
            this.currentChapter.pages[4].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Loss-Funktion und der Optimizer geben die Strategie an, mit der ' +
                'das Netz das tiefste Tal findet.', ['p-large'])
                }, ''));

            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Diese Zeile führt das Training aus und speichert die ' +
                        'Werte für die Genauigkeit, die das Netz schon erreicht hat, in der Variable "history".', [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('code-element',
                {
                    content: [
                        'history = model.fit(train_images, train_labels, epochs=10)']
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Jetzt können wir ihn ausführen und das Netz wird trainiert!', [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Training kann Minuten, Stunden oder auch Tage lang dauern. ' +
                        'In diesem Fall wurden drei Netze bereits fertig trainiert und auf einem Server gespeichert. ' +
                        'Du bekommst jedes Mal zufällig die Ergebnisse eines dieser Netze wenn Du auf diese Seite kommst. ', [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die "history" enthält eine Übersicht der Fehlerquote während des Trainings: ', [])
                }, ''));
            /*this.currentChapter.pages[5].content.push(new CustomComponent('button-list',
                {
                    content: [new ButtonListContent('Training starten!', false, false, 'star_rate_black_24dp.svg')],
                    isTwoCol: true,
                    actionType: 'start-training'
                }, ''));*/
            this.currentChapter.pages[5].content.push(new CustomComponent('custom-image',
                {
                    isNetwork: false,
                    path: process.env.VUE_APP_PYTHON_SERVER_ADDRESS + '/' + this.dataset + '/history',
                    width: 500
                }, ''));
            this.currentChapter.pages[5].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Training wird in Epochen durchgeführt, wobei in jeder Epoche die ' +
                'gesamten Trainingsdaten einmal vom Netz angesehen werden.', ['p-large'])
                }, ''));

            this.currentChapter.pages[6].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Fassen wir den gesamten Code noch einmal zusammmen: ', [])
                }, ''));
            if (this.dataset === 'fashion') {
                this.currentChapter.pages[6].content.push(new CustomComponent('code-element',
                    {
                        content: [
                            'import tensorflow as tf',
                            '',
                            'dataset = tf.keras.datasets.fashion_mnist',
                            '(train_images, train_labels), ',
                            '    (test_images, test_labels) = dataset.load_data()',
                            'train_images = train_images / 255.0',
                            'test_images = test_images / 255.0',
                            'model = tf.keras.Sequential([',
                            '   tf.keras.layers.Flatten(input_shape=(28, 28)),',
                            '   tf.keras.layers.Dense(128, activation="relu"),',
                            '   tf.keras.layers.Dense(10)',
                            'model.compile(optimizer="adam"',
                            '   loss=tf.keras.losses.SparseCategoricalCrossentropy(from_logits=True),',
                            '   metrics=["accuracy"])',
                            'history = model.fit(train_images, train_labels, epochs=10)']
                    }, ''));
            } else {
                this.currentChapter.pages[6].content.push(new CustomComponent('code-element',
                    {
                        content: [
                            'import tensorflow as tf',
                            '',
                            'dataset = tf.keras.datasets.mnist',
                            '(train_images, train_labels), ',
                            '    (test_images, test_labels) = dataset.load_data()',
                            'train_images = train_images / 255.0',
                            'test_images = test_images / 255.0',
                            'model = tf.keras.Sequential([',
                            '   tf.keras.layers.Flatten(input_shape=(28, 28)),',
                            '   tf.keras.layers.Dense(128, activation="relu"),',
                            '   tf.keras.layers.Dense(10)',
                            'model.compile(optimizer="adam"',
                            '   loss=tf.keras.losses.SparseCategoricalCrossentropy(from_logits=True),',
                            '   metrics=["accuracy"])',
                            'history = model.fit(train_images, train_labels, epochs=10)']
                    }, ''));
            }
            this.currentChapter.pages[6].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Mit diesen wenigen Zeilen Code kann ein Netz Kleidungsstücke oder ' +
                'Zahlen voneinander unterscheiden lernen!', ['p-large'])
                }, ''));
            this.currentChapter.pages[6].summary.push(new CustomComponent('link-button',
                {
                    text: 'Code zum selbst ausprobieren!',
                    link: 'https://colab.research.google.com/drive/1m2cg3D1x3j5vrFc-Cu0gMvc48gWyCOuG#forceEdit=true&sandboxMode=true',
                    color: '#A8B764',
                }, ''));
            // https://colab.research.google.com/drive/1m2cg3D1x3j5vrFc-Cu0gMvc48gWyCOuG#forceEdit=true&sandboxMode=true

        }
    }
</script>

<style scoped>

</style>