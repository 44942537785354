<template>
    <img class="image shadow" :style="imageWidth" :src="image" alt="picture of clothing">
</template>

<script>
    export default {
        name: "custom-image",
        props: {
            isNetwork: Boolean,
            path: String,
            width: Number
        },
        data() {
            return {
                image: 'fashion.png',
                currentImage: 0,
                dataset: '',
            }
        },
        computed: {
            imageWidth() {
                return {
                    width: `${this.width}px`,
                    height: `auto`
                }
            },
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.image = this.dataset + '.png';
            if(this.isNetwork){
                const endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS + '/' + this.dataset + '/';
                this.randomImage();
                this.image = endpoint + this.currentImage;
            } else {
                this.image = this.path;
            }
        },
        methods: {
            randomImage: function () {
                this.currentImage = Math.floor(Math.random() * (100 - 1 + 1)) + 1;
            },
        }
    }
</script>

<style scoped>

</style>