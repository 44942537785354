<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          :prev="chapters[0].link + '/:' + chapters[0].pages.length" :next="chapters[2].link"
                          :prevTitle="chapters[0].title" :nextTitle="chapters[2].title">
    </SingleSidebarChapter>
</template>

<script>
    import Dataset from "../models/Dataset";
    import content from "../assets/ChapterList.json";
    import SingleSidebarChapter from "../components/layouts/SingleSidebarChapter";
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import ButtonListContent from "../models/ButtonListContent";

    export default {
        name: "Chapter1",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                dataList: new Dataset(process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + 'fashion/', process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + 'fashion_label/',process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + 'fashion_net/',
                    ['T-shirt/top', 'Hose', 'Pullover', 'Kleid', 'Jacke',
                        'Sandale', 'Hemd', 'Sneaker', 'Tasche', 'Stiefel'],
                    ['#D5BE95', '#EA655E', '#C86E90', '#946DAE', '#667BA5',
                        '#7ABBB7', '#66A464', '#A8B764', '#D2BA5A', '#D6A15C']),
                currentPage: 0,
                pages: [0, 1],
                dataset: ''
            }
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[1];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
                this.currentChapter.pages[i].summary = [];
            }
            if (this.dataset === 'fashion') {
                this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                    {
                        content: new TextElementContent('p', 'Damit Du eine bessere Vorstellung davon ' +
                            'bekommst, wie schwer oder leicht es ist, Kleidungsstücke anhand von Bildern einzuordnen, ' +
                            'kannst Du die Aufgabe hier einmal selbst ausprobieren.', [])
                    }, ''));
            } else {
                this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                    {
                        content: new TextElementContent('p', 'Damit Du eine bessere Vorstellung davon ' +
                            'bekommst, wie schwer oder leicht es ist, handgeschrieben Ziffern anhand von Bildern einzuordnen, ' +
                            'kannst Du die Aufgabe hier einmal selbst ausprobieren.', [])
                    }, ''));
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('custom-image',
                {isNetwork: true}, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Vielleicht erscheinen Dir diese Aufgaben viel zu einfach. ' +
                'Vielleicht fällt es Dir aber auch selbst schwer, anhand von nur 28x28 Pixeln eine Bedeutung ' +
                'in die Bilder zu interpretieren.', ['p-large'])}, ''));

            if (this.dataset === 'fashion') {
                this.currentChapter.pages[1].content.push(new CustomComponent('two-col-layout',
                    {
                        topCol: [],
                        leftCol: [
                            new CustomComponent('text-element',
                                {
                                    content: new TextElementContent('p', 'Dir werden gleich nacheinander 5 zufällige Bilder gezeigt. ' +
                                        'Die Bilder sind in derselben Auflösung wie sie unser Künstliches neuronales Netz später ' +
                                        'auch sehen wird.', ['text-left'])
                                }, ''),
                            new CustomComponent('text-element',
                                {
                                    content: new TextElementContent('p', 'In jeder Runde wählst Du eine der 10 möglichen ' +
                                        'Kategorien, zu der das Kleidungsstück Deiner Meinung nach gehört. Anschließend wird ' +
                                        'Deine Vermutung mit der zugehörigen Antwort verglichen.', ['text-left'])
                                }, ''),

                        ],
                        rightCol: [
                            new CustomComponent('button-list',
                                {
                                    content: this.createButtons(),
                                    isTwoCol: true,
                                    actionType: ''
                                }, '')
                        ],
                        botCol: []
                    }, ''));
            } else {
                this.currentChapter.pages[1].content.push(new CustomComponent('two-col-layout',
                    {
                        topCol: [],
                        leftCol: [
                            new CustomComponent('text-element',
                                {
                                    content: new TextElementContent('p', 'Dir werden gleich nacheinander 5 zufällige Bilder gezeigt. ' +
                                        'Die Bilder sind in derselben Auflösung wie sie unser Künstliches neuronales Netz später ' +
                                        'auch sehen wird.', ['text-left'])
                                }, ''),
                            new CustomComponent('text-element',
                                {
                                    content: new TextElementContent('p', 'In jeder Runde wählst Du eine der 10 möglichen ' +
                                        'Ziffern. Anschließend wird Deine Vermutung mit der zugehörigen Antwort verglichen.', ['text-left'])
                                }, '')

                        ],
                        rightCol: [
                            new CustomComponent('button-list',
                                {
                                    content: this.createButtons(),
                                    isTwoCol: true,
                                    actionType: ''
                                }, '')
                        ],
                        botCol: []
                    }, ''));
            }
            this.currentChapter.pages[1].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Dieses kleine Spiel soll Dir dabei helfen, besser einzuschätzen, ' +
                'wie die Ergebnisse von KNNs bewertet werden können.', ['p-large'])}, ''));

            this.currentChapter.pages[2].content.push(new CustomComponent('classification-game', {playAgainstNet: false}, ''));
            this.currentChapter.pages[2].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Es ist ratsam mindestens 5 Runden zu spielen. Du kannst aber ' +
                'auch so lange spielen wie Du möchtest. Danach werden wir uns ansehen, was man aus diesem ' +
                'Selbstversuch lernen kann.', ['p-large'])}, ''));

            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Hier kannst Du Dir nochmal Deine Antworten ansehen.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('image-grid', {
                showUserResults: true
            }, ''));

            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Vielleicht denkst Du Dir bei manchen Beispielen, dass Deine' +
                        ' Antwort richtig war anstatt der angeblich' +
                        ' "richtigen" Antwort.', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('pb', 'Dann bist Du auf ein wichtiges Problem gestoßen!', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Da wir mit diesen Daten unser Netz trainieren wollen,' +
                        ' müssen wir für jedes Beispiel die "richtige" Antwort vorgeben, damit das Netz weiß, ' +
                        'ob seine Antwort richtig war und wenn nicht, was die richtige Antwort gewesen wäre.', [])
                }, ''));

            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'In diesem Fall haben die Leute, die die Fotos gesammelt ' +
                        'haben schon an jedes Foto die "richtige" Antwort geschrieben. Dabei ist die Definition, ' +
                        'welches Kleidungsstück nun als "Pullover" oder "Jacke" oder welcher Krakel als "1" oder ' +
                        '"7" bezeichnet wird eine komplett von Menschen erfundene Einteilung.', [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('image-grid', {
                showUserResults: false
            }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Einteilung kann von Land zu Land und sogar von Mensch ' +
                        'zu Mensch unterschiedlich sein. Irgendeine Gruppe von Studenten wurde wahrscheinlich ' +
                        'vor die Bilder gesetzt und musste sie in die Kategorien einteilen. Deine Definition von' +
                        ' "Jacke" oder "7" muss nicht mit der Definition eines dieser Studenten überein stimmen.', [])
                }, ''));
            this.currentChapter.pages[5].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Festzulegen, was "richtig" und "falsch" für das Netz bedeutet, ' +
                'ist nicht immer so einfach!', ['p-large'])}, ''));
        },
        methods: {
            createButtons: function () {
                const train_endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + this.dataset + '/';
                const train_label_endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + this.dataset + '_label/';
                const test_endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + this.dataset + '/test/';
                const net_endpoint = process.env.VUE_APP_PYTHON_SERVER_ADDRESS
                    + '/' + this.dataset + '/prediction/';
                let labels = [];
                if (this.dataset === 'digit') {
                    labels = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
                } else if (this.dataset === 'fashion') {
                    labels = ['T-shirt/top', 'Hose', 'Pullover', 'Kleid', 'Jacke',
                        'Sandale', 'Hemd', 'Sneaker', 'Tasche', 'Stiefel'];
                }
                this.dataList = new Dataset(train_endpoint, train_label_endpoint, net_endpoint, test_endpoint, labels,
                    ['#D5BE95', '#EA655E', '#C86E90', '#946DAE', '#667BA5',
                        '#7ABBB7', '#66A464', '#A8B764', '#D2BA5A', '#D6A15C']);
                let list = [];
                for (let i in this.dataList._classList) {
                    list.push(new ButtonListContent(i, true, true, this.dataList._colorList[i]));
                }
                return list;
            }
        }
    }
</script>

<style scoped>

</style>