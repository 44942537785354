<template>
    <div class="btn shadow" :style="{ backgroundColor: color}">
        <img class="btn-icon" src="explore_black_24dp.svg" alt="i"/>
        <a class="btn-text" :href="link">{{text}}</a>
    </div>
</template>

<script>
    export default {
        name: "link-button",
        props: {
            text: String,
            link: String,
            color: String
        }
    }
</script>

<style scoped>
    .btn {
        display: grid;
        border-radius: 6px;
        align-items: start;
        cursor: pointer;
        grid-template-columns: 30px auto;
        grid-template-areas: 'icon text';
        justify-items: flex-start;
        margin: 1em;
    }

    .btn a {
        text-decoration: none;
        color: var(--text-dark);
    }

    .btn-icon {
        width: 30px;
        height: 30px;
        margin: auto;
        grid-area: icon;
        padding: 6px;
    }

    .btn-text {
        padding: 5px 20px;
        text-align: left;
        vertical-align: center;
        margin: auto;
        grid-area: text;
        font-size: 14pt;
    }
</style>