<template>
    <div>
        <h1 v-if="content.html === 'h1'" :class="content.classList">{{content.content}}</h1>
        <h2 v-if="content.html === 'h2'" :class="content.classList">{{content.content}}</h2>
        <h3 v-if="content.html === 'h3'" :class="content.classList">{{content.content}}</h3>
        <h4 v-if="content.html === 'h4'" :class="content.classList">{{content.content}}</h4>
        <p v-if="content.html === 'p'" :class="content.classList">{{content.content}}</p>
        <p v-if="content.html === 'pb'" :class="content.classList"><b>{{content.content}}</b></p>
        <p v-if="content.html === 'pa'" :class="content.classList" v-html="content.content"></p>
    </div>
</template>

<script>
    import TextElementContent from "../models/TextElementContent";

    export default {
        name: "text-element",
        props: {
            content: TextElementContent
        }
    }
</script>

<style scoped>

</style>