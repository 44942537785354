<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          :prev="chapters[2].link + '/:' + chapters[2].pages.length"
                          :next="chapters[4].link" :prevTitle="chapters[2].title"
                          :nextTitle="chapters[4].title"></SingleSidebarChapter>
</template>

<script>
    import SingleSidebarChapter from '../components/layouts/SingleSidebarChapter.vue'
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import PixelImage from "../models/PixelImage";
    import content from "../assets/ChapterList.json";

    export default {
        name: "Chapter3",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                chapters: [],
                currentChapter: {},
                horLine1: [0, 0, 1, 1],
                horLine2: [1, 1, 0, 0],
                vertLine1: [1, 0, 1, 0],
                vertLine2: [0, 1, 0, 1],
                white: [1, 1, 1, 1],
                largeImage: [],
                dataset: ''
            }
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.largeImage = Array.from({length: 784}, () => Math.round(Math.random()));
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[3];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
                this.currentChapter.pages[i].summary = [];
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie sieht das einfachste Netz aus?', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Im Jahr 1957 stellte der amerikanische Psychologe ' +
                        'Frank Rosenblatt ein Modell für einen Computer vor, der selbst lernen können sollte! ' +
                        'Es sollte durch "trial and error" (Versuch und Irrtum) neues Wissen generieren. ' +
                        'Dieses Modell war sein Versuch, ein menschliches Neuron als Computer nachzubauen. ' +
                        'Daher heißt das Modell "Perceptron", von Wahrnehmung (Perception).', [])
                }, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Computer sind Rechenmaschinen. Sie bekommen Anweisungen ' +
                        'in Form von Rechenoperationen. Aber wer gibt ihnen diese Anweisungen?', ['p-large'])
                }, ''));

            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Modell wurde 1960 wirklich mit Hardware aufgebaut. ' +
                        'Dieser Computer hieß Mark I Perceptron (siehe Bild).', [])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('custom-image',
                {isNetwork: false, path: 'mark1.png', width: 500}, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('pa', '<a href="https://americanhistory.si.edu/collections/search' +
                        '/object/nmah_334414">National Museum of American History</a>', [])
                }, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Perceptron bildet die Grundlage für Künstliche Neuronale Netze.', ['p-large'])
                }, ''));

            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'In unserem sehr einfachen Beispiel, besteht ein "Bild" ' +
                        'aus 2x2 Pixeln.', ['italic'])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('pixel-grid',
                {
                    content: [new PixelImage(2, false, this.white, false, 40, false)]
                }
            ));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn wir für jede 1 die Farbe schwarz und für jede ' +
                        '0 die Farbe weiß festlegen, sieht es schon fast wieder wie ein Bild aus.', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('pixel-grid',
                {
                    content: [new PixelImage(2, false, this.vertLine1, false, 40, true)]
                }
            ));
            this.currentChapter.pages[2].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie kann man Bilder mit einem Computer darstellen?', ['p-large'])
                }, ''));

            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Ein Computer kann mit dieser grafischen Darstellung nicht ' +
                        'viel anfangen. Menschen fällt es oft leichter, Informationen mit den Augen zu erfassen, als ' +
                        'nur Zahlen zu sehen. Der Computer kann am einfachsten mit schön aufgelisteten Zahlen rechnen.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Am liebsten hätte er die Werte der Pixel als Liste: [' +
                        this.vertLine1 + '].', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('pixel-grid',
                {
                    content: [
                        new PixelImage(2, false, this.vertLine1, false, 40, true),
                        new PixelImage(2, true, this.vertLine1, false, 40, true)
                    ]
                }
            ));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wir müssen unser Bild also platt machen! Dabei spielt es ' +
                        'keine Rolle, ob wir Zeile für Zeile oder Spalte für Spalte vorgehen, Hauptsache wir machen ' +
                        'es immer auf dieselbe Weise.', [])
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Ein Computer verarbeitet ein Bild als Liste der Werte der Pixel.', ['p-large'])
                }, ''));

            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Diese Liste der Pixel kann jetzt an ein Perceptron übergeben ' +
                        'werden!', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Daten, die an das Perceptron zum Verarbeiten gegeben ' +
                        'werden, werden Eingaben (Inputs) genannt.', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 1,
                    showData: true
                }
            ));
            this.currentChapter.pages[4].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Für jeden Pixel gibt es einen Input.', ['p-large'])
                }, ''));

            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Jeder dieser Inputs bekommt eine Zahl zugeordnet. Diese Zahl ' +
                        'gibt an, wie sehr der Input bei der Verarbeitung beachtet wird. Also wie wichtig der bestimmte ' +
                        'Pixel für die Auswertung des Bildes ist. Daher heißen diese Zahlen Gewichte (Weights). ' +
                        'Die Gewichtung kann zum Beispiel mit Zahlen zwischen 0 und 1 vorgenommen werden.',
                        [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 2,
                    showData: false
                }
            ));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Percepton lernt, die Bilder zu interpretieren, indem es die ' +
                        'Gewichte anpasst. Also lernt es, welche Pixel es besonders beachten soll, um eine ' +
                        '1 von einer 2 oder eine Jacke von einer Hose zu unterschieden.',
                        [])
                }, ''));
            this.currentChapter.pages[5].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'In den Gewichten steckt das Wissen des Netzes!', ['p-large'])
                }, ''));

            this.currentChapter.pages[6].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Percepton nimmt die Information, wie wichtig jeder Pixel ist ' +
                        'und fasst diese Informationen zu einer Entscheidung zusammen. Dafür wird jeder Input mit seinem ' +
                        'zugehörigen Gewicht multipilziert und anschließend alle Werte addiert.',
                        [])
                }, ''));
            this.currentChapter.pages[6].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 3,
                    showData: false
                }
            ));
            this.currentChapter.pages[6].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Die Berechnung wird aus Additionen und Multiplikationen gebildet, ' +
                        'die Computer sehr schnell ausführen können.', ['p-large'])
                }, ''));

            this.currentChapter.pages[7].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Nach der Berechnung der Pixel-Werte und deren ' +
                        'Gewichte bekommen wir also zum Beispiel die Zahl 0,37. Was sagt uns diese Zahl? ' +
                        'Das Perceptron kann mit dieser Zahl ausdrücken, wie wahrscheinlich das eingegebene Bild einer ' +
                        'bestimmten Klasse angehört. In unserem Fall soll es eine 1 für eine vertikale Linie ' +
                        'und eine 0 für alle anderen möglichen Kombinationen aus schwarzen und weißen Pixeln ausgeben.',
                        [])
                }, ''));
            this.currentChapter.pages[7].content.push(new CustomComponent('custom-image',
                {isNetwork: false, path: 'aktivierungsfunktion2.png', width: 500}, ''));
            this.currentChapter.pages[7].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wir müssen also festlegen, welche Werte, die bei der Berechnung ' +
                        'entstehen eher eine 1 oder eine 0 sind. Dazu benutzen wir eine sogeannate Aktivierungsfunktion. ' +
                        'Wenn wir eine 0,37 von der Berechnung erhalten, suchen wir diesen Wert auf der x-Achse und ' +
                        'lesen den Wert (0 oder 1) auf der y-Achse ab.',
                        [])
                }, ''));
            this.currentChapter.pages[7].content.push(new CustomComponent('custom-image',
                {isNetwork: false, path: 'aktivierungsfunktion.png', width: 500}, ''));
            this.currentChapter.pages[7].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Dabei können wir festlegen, wo der Grenzwert der Entscheidung ' +
                        'liegt, indem wir die Funktion verschieben (siehe Farben).',
                        [])
                }, ''));
            /*this.currentChapter.pages[7].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 4,
                    showData: false
                }
            ));*/

            this.currentChapter.pages[7].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Ursprünglich hat das Perceptron mit der hier gezeigten Funktion ' +
                        'gearbeitet, heutzutage gibt es aber viele verschiedene Aktivierungsfunktionen.', ['p-large'])
                }, ''));
            this.currentChapter.pages[7].summary.push(new CustomComponent('link-button',
                {
                    text: 'Video zum Thema',
                    link: 'https://www.youtube.com/watch?v=s-V7gKrsels',
                    color: '#A8B764',
                }, ''));
            this.currentChapter.pages[7].summary.push(new CustomComponent('link-button',
                {
                    text: 'Artikel zum Thema',
                    link: 'https://www.analyticsvidhya.com/blog/2020/01/fundamentals-deep-learning-activation-functions-when-to-use-them/',
                    color: '#A8B764',
                }, ''));
            // https://www.analyticsvidhya.com/blog/2020/01/fundamentals-deep-learning-activation-functions-when-to-use-them/
            // https://www.youtube.com/watch?v=s-V7gKrsels
            // https://www.youtube.com/watch?v=m0pIlLfpXWE
            // https://paperswithcode.com/methods/category/activation-functions

            this.currentChapter.pages[8].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Nachdem die Berechnung der Inputs und Gewichte einen einzelnen ' +
                        'Wert liefert, wird dieser auf der x-Achse der Aktivierungsfunktion abgelesen (in die Funktion ' +
                        'eingesetzt) und entweder auf eine 1 oder eine 0 umgerechnet.',
                        [])
                }, ''));
            this.currentChapter.pages[8].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 4,
                    showData: true
                }
            ));
            this.currentChapter.pages[8].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn Du mit der Maus über das Diagramm fährst, kannst Du die ' +
                        'richtige Zahl für den Output ablesen. (Tipp: die Funktion wird auch für Werte größer als 1 ' +
                        'weitergeführt)',
                        [])
                }, ''));
            this.currentChapter.pages[8].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Ursprünglich hat das Perceptron mit der hier gezeigten Funktion ' +
                        'gearbeitet, heutzutage gibt es aber viele verschiedene Aktivierungsfunktionen.', ['p-large'])
                }, ''));

            this.currentChapter.pages[9].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Das Ergebnis ist nach unserer Aktivierungsfunktion entweder ' +
                        'die Zahl 1 oder die Zahl 0. Die 1 steht dann z.B. für eine vertikale Linie und die 0 für ' +
                        'ein Bild der anderen möglichen Kombinationen. Das Ergebnis eines Perceptrons wird meist ' +
                        'Ausgabe (Output) genannt.',
                        [])
                }, ''));
            this.currentChapter.pages[9].content.push(new CustomComponent('perceptron',
                {
                    inputData: new PixelImage(2, true, this.vertLine1, false, 40, true),
                    step: 5,
                    showData: false
                }
            ));
            this.currentChapter.pages[9].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Damit ist die erste Vorhersage des Netzes getroffen! Wir wissen ' +
                        'jetzt, ob das Netz das eingegebene Bild als horizontale erkannt wurde oder nicht. ' +
                        'Da wir die Gewichte aber einfach zufällig gewählt haben, kann die Vorhersage zufällig richtig ' +
                        'oder falsch sein.',
                        [])
                }, ''));
            this.currentChapter.pages[9].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie können wir die Gewichte so anpassen, dass die Vorhersagen immer ' +
                        'zutreffen?', ['p-large'])
                }, ''));

        }
    }
</script>

<style scoped>
    a:link {
        color: var(--light-blue);
    }

    a:visited {
        color: var(--light-green);
    }

</style>