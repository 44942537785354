<template>
    <SingleSidebarChapter v-if="currentChapter.chapterLayout === 'double-sidebar'" :currentChapter="{currentChapter}"
                          :prev="chapters[1].link + '/:' + chapters[1].pages.length" :next="chapters[3].link"
                          :prevTitle="chapters[1].title" :nextTitle="chapters[3].title">
    </SingleSidebarChapter>
</template>

<script>
    import SingleSidebarChapter from '../components/layouts/SingleSidebarChapter.vue'
    import CustomComponent from "../models/CustomComponent";
    import TextElementContent from "../models/TextElementContent";
    import PixelImage from "../models/PixelImage";
    import content from "../assets/ChapterList.json";

    export default {
        name: "Chapter2",
        components: {
            SingleSidebarChapter
        },
        props: {
            page: String
        },
        data() {
            return {
                chapters: [],
                currentChapter: {},
                horLine1: [0, 0, 1, 1],
                horLine2: [1, 1, 0, 0],
                vertLine1: [1, 0, 1, 0],
                vertLine2: [0, 1, 0, 1],
                isFlattened: false,
                largeImage: [],
            }
        },
        created() {
            this.dataset = this.$store.state.dataset;
            this.largeImage = Array.from({length: 784}, () => Math.round(Math.random()));
            this.chapters = content.chapters;
            this.currentChapter = this.chapters[2];
            for (let i = 0; i < this.currentChapter.pages.length; i++) {
                this.currentChapter.pages[i].content = [];
                this.currentChapter.pages[i].summary = [];
            }
            this.currentChapter.pages[0].content.push(new CustomComponent('custom-image', {isNetwork: true}, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Als Mensch lösen wir die Aufgabe vielleicht, indem wir uns ' +
                        'ansehen, ob das Kleidungsstück Ärmel hat, dann kann es kein T-Shirt sein. Wenn es zwei ' +
                        'Beine hat, ist es eine Hose. Oder wir sehen uns an, ob die Zahl zwei Kreise hat, dann ist ' +
                        'es eine 8. Wenn es nur ein vertikaler Strich ist, ist es eine 1.', [])
                }, ''));
            this.currentChapter.pages[0].content.push(new CustomComponent('text-element',
                {content: new TextElementContent('p', 'Wie kann man einem Computer solche Regeln beibringen?', [])}, ''));
            this.currentChapter.pages[0].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Computer sind Rechenmaschinen. Sie bekommen Anweisungen ' +
                        'in Form von Rechenoperationen. Aber wer gibt ihnen diese Anweisungen?', ['p-large'])
                }, ''));

            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn ein Entwickler Quellcode schreibt, legt er genau ' +
                        'fest, welche Anweisungen in welcher Reihenfolge bearbeitet werden, damit das Programm ' +
                        'funktioniert.', [])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Angenommen wir würden die Bilder mit einem Algorithmus ' +
                        'klassifizieren wollen. Dann müssten wir als Entwickler eine ganze Reihe an Regeln ' +
                        'festlegen:', [])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('pb', '"Wenn der 20. Pixel von links oben weiß ist und die 4 Pixel ' +
                        'in der Mitte des Bildes schwarz, dann ist das Bild ein Schuh bzw. eine 6"', ['italic'])
                }, ''));
            this.currentChapter.pages[1].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Kein Mensch kann vorhersagen, wie viele solcher Regeln ' +
                        'man brauchen würde. Außerdem müssten diese Regeln nicht nur als Quellcode formuliert, ' +
                        'sondern auch erstmal mühevoll von einem Menschen herausgefunden werden.', [])
                }, ''));
            this.currentChapter.pages[1].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie würde ein solcher Algorithmus aussehen?', ['p-large'])
                }, ''));

            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie könnte ein Algorithmus für die Erkennung von ' +
                        'Bildern aussehen?', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('pixel-grid',
                {
                    content: [new PixelImage(2, false, this.horLine1, false, 40, true)]
                }
            ));
            this.currentChapter.pages[2].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Unsere Aufgabe ist es, horizontale und vertikale Linien zu ' +
                        'erkennen. Also die folgenden Kombinationen:', [])
                }, ''));
            this.currentChapter.pages[2].content.push(new CustomComponent('pixel-grid',
                {
                    content: [
                        new PixelImage(2, false, this.horLine1, false, 40, true),
                        new PixelImage(2, false, this.horLine2, false, 40, true),
                        new PixelImage(2, false, this.vertLine1, false, 40, true),
                        new PixelImage(2, false, this.vertLine2, false, 40, true)
                    ]
                }
            ));
            this.currentChapter.pages[2].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wir betrachten für dieses Beispiel das einfachste mögliche ' +
                        '"Bild".', ['p-large'])
                }, ''));

            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Zunächst müsste man sich überlegen, wie man diese Regeln ' +
                        'aufschreiben könnte. Wir könnten die Pixel nummerieren und für jeden prüfen, ' +
                        'welche Farbe er haben muss.', [])
                }, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('pixel-grid',
                {
                    content: [new PixelImage(2, false, this.vertLine1, false, 40, true)]
                }
            ));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {content: new TextElementContent('p', 'Eine Regel könnte also lauten:', [])}, ''));
            this.currentChapter.pages[3].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', '0 == weiß && 1 == schwarz && 2 == weiß && 3 == schwarz:' +
                        ' vertikale Linie', ['italic'])
                }, ''));
            this.currentChapter.pages[3].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Einfach ausgedrückt bedeutet Bilder zu erkennen, das gemeinsame ' +
                        'Auftreten von Helligkeitswerten (und Farbwerten) in Regeln zu fassen.', ['p-large'])
                }, ''));

            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie würden die Regeln lauten, wenn wir alle aufzählen ' +
                        'würden?', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('pixel-grid',
                {
                    content: [
                        new PixelImage(2, false, this.horLine1, false, 40, true),
                        new PixelImage(2, false, this.horLine2, false, 40, true),
                        new PixelImage(2, false, this.vertLine2, false, 40, true)
                    ]
                }
            ));
            this.currentChapter.pages[4].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Du kannst selbst überlegen, welche Regeln es noch geben' +
                        ' müsste und sie dann kontrollieren.', [])
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('info-element',
                {
                    content: '0 == schwarz && 1 == schwarz && 2 == weiß && 3 == weiß: horizontale Linie',
                    color: 'var(--light-green)'
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('info-element',
                {
                    content: '0 == weiß && 1 == weiß && 2 == schwarz && 3 == schwarz: horizontale Linie',
                    color: 'var(--orange)'
                }, ''));
            this.currentChapter.pages[4].content.push(new CustomComponent('info-element',
                {
                    content: '0 == schwarz && 1 == weiß && 2 == weiß && 3 == schwarz: vertikale Linie',
                    color: 'var(--light-blue)'
                }, ''));
            this.currentChapter.pages[4].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Versuche einmal selbt, die Regeln aufzustellen.', ['p-large'])
                }, ''));

            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wenn man das auf unsere Aufgabe übertragen würde, ' +
                        'Kleidung/Ziffern zu erkennen, müssten die Regeln sehr viel komplizierter aussehen!', [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Mit 2x2 Pixeln sind diese Regeln noch relativ einfach. ' +
                        'Aber möchtest Du diese Regeln für ein Bild mit 28x28 Pixeln selbst aufstellen müssen?', [])
                }, ''));
            this.currentChapter.pages[5].content.push(new CustomComponent('pixel-grid',
                {
                    content: [new PixelImage(8, false, this.largeImage, false, 10, false)]
                }
            ));
            this.currentChapter.pages[5].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('pb', 'Wäre es nicht genial, wenn ein Computer diese Regeln ' +
                        'selber herausfinden könnte?', [])
                }, ''));
            this.currentChapter.pages[5].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Wie kann ein Computer selbst solche Regeln aufstellen?', ['p-large'])
                }, ''));

            this.currentChapter.pages[6].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Künstliche Neuronale Netze sind für das selbstständige' +
                        ' Aufstellen solcher Regeln (wie) geschaffen!', [])
                }, ''));
            this.currentChapter.pages[6].content.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Ein Künstliches Neuronales Netz wird wie ein Algorithmus ' +
                        'mit festen Abläufen programmiert. Es wird ihm aber nur gesagt, WIE es die Regeln aufstellen ' +
                        'kann und nicht WELCHE es lernen soll. Es sieht sich dazu meist viele tausend Bilder ' +
                        '(oder andere Daten) an und stellt dabei viele dieser Regeln selbst auf.', [])
                }, ''));
            this.currentChapter.pages[6].summary.push(new CustomComponent('text-element',
                {
                    content: new TextElementContent('p', 'Aber wie kann ein Netz diese Regeln selbst erstellen?', ['p-large'])
                }, ''));
        }
    }
</script>

<style scoped>

</style>