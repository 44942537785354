<template>
    <!--<CustomButton v-if="!done" class="grad-button" image="star_rate_black_24dp.svg" v-bind:is-color="false" v-bind:is-dark="false"
                  text="Runde starten" color="#66A464" v-on:click="startRun"></CustomButton>-->
    <div v-if="!done">
        <p>Stell Dir vor, du bist in einem Gebirge. Es wird dunkel und neblig. Du musst schnell das tiefste Tal
            finden, weil Du weißt, dass dort ein Dorf ist. Du kannst allerdings nur drei Meter weit sehen und in
            jede Richtung die Höhe über (oder unter) dem Meeresspiegel messen. (Ja das Dorf könnte auch Atlantis
            sein.)</p>
    </div>
    <div v-if="error">
        <p class="p-orange">{{error}}</p>
    </div>
    <div v-if="!done" class="compass-grid">
        <CustomSquareButton image="explore_black_24dp.svg" v-bind:is-color="false" v-bind:is-dark="false"
                            v-for="(n, index) in currentNeighbours" v-bind:key="index" :text="(n*-1).toString()"
                            :color="btnColors[index]" v-on:click="takeStep(index)"></CustomSquareButton>
    </div>
    <CustomButton v-if="!done" class="grad-button" image="star_rate_black_24dp.svg" v-bind:is-color="false"
                  v-bind:is-dark="false"
                  text="Im Tal angekommen" color="#66A464" v-on:click="getChart"></CustomButton>
    <div v-if="!done">
        <p>Du befindest Dich an der Position in der Mitte (orange) und wählst in jedem Schritt eine Richtung aus
            (grün), in die Du dann bewegt wirst. Wenn Du glaubst, im Tal angekommen zu sein, kannst Du Deinen Weg
            anzeigen lassen.</p>
    </div>
    <iframe class="chart" v-if="done" :src="url"></iframe>
    <div v-if="done">
        <p>Bist Du ganz unten angekommen? Oder steckst Du in einem kleinen Tal irgendwo in der Mitte, obwohl Du keine
            kleinere Zahl mehr messen konntest? Wenn der zweite Fall zutrifft, bist Du auf ein wichtiges Problem
            gestoßen:
            es ist sehr einfach, in einem sogenannten lokalen Minimum stecken zu bleiben, ohne herausfinden zu können,
            ob
            man nur noch ein kleines Stück wieder bergauf gehen müsste, um ein tieferes Tal zu finden!</p>
        <p>Gehe gerne noch einmal zurück und versuche es nochmal! (Es könnte sein, dass du die Seite neu laden musst,
            um wieder an den Anfang zu kommen.)</p>
    </div>
    <CustomButton v-if="done" class="grad-button" image="sports_esports_black_24dp.svg" v-bind:is-color="false"
                  v-bind:is-dark="false"
                  text="zurück" color="#66A464" v-on:click="goBack"></CustomButton>
</template>

<script>
    import CustomButton from './CustomButton.vue';
    import CustomSquareButton from './CustomSquareButton.vue';

    export default {
        name: "gradient-descent-game",
        components: {
            CustomButton,
            CustomSquareButton
        },
        data() {
            return {
                currentNeighbours: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                chart: '',
                done: false,
                url: '',
                btnColors: [],
                error: ''
            }
        },
        created() {
            this.startRun();
            this.done = false;
            this.btnColors = [];
        },
        methods: {
            startRun: function () {
                fetch(process.env.VUE_APP_PYTHON_SERVER_ADDRESS + '/graddesc/start', {
                    "method": "GET",
                    credentials: 'include'
                })
                    .then(response => {
                        if (response.ok) {
                            this.error = '';
                            return response.json();
                        } else {
                            // alert("Server returned " + response.status + " : " + response.statusText);
                            this.error = 'Es ist leider ein Fehler bei der Berechnung aufgetreten.';
                        }
                    })
                    .then(response => {
                        this.currentNeighbours = response.neighbours;
                        for (let i = 0; i < this.currentNeighbours.length; i++) {
                            if (i === Math.round(this.currentNeighbours.length / 2) - 1) {
                                this.btnColors.push('var(--orange)');
                            } else {
                                this.btnColors.push('var(--dark-green)');
                                console.log('test');
                            }
                        }
                        console.log(this.btnColors);
                    })
                    .catch(err => {
                        this.error = 'Es ist leider ein Fehler bei der Berechnung aufgetreten.';
                        console.log(err);
                    });
            },
            takeStep: function (n) {
                let x, y;
                switch (n) {
                    case 0:
                        x = -1;
                        y = -1;
                        break;
                    case 1:
                        x = 0;
                        y = 1;
                        break;
                    case 2:
                        x = 1;
                        y = -1;
                        break;
                    case 3:
                        x = -1;
                        y = 0;
                        break;
                    case 4:
                        x = 0;
                        y = 0;
                        break;
                    case 5:
                        x = 1;
                        y = 0;
                        break;
                    case 6:
                        x = -1;
                        y = 1;
                        break;
                    case 7:
                        x = 0;
                        y = -1;
                        break;
                    case 8:
                        x = 1;
                        y = 1;
                        break;

                }
                fetch(process.env.VUE_APP_PYTHON_SERVER_ADDRESS + '/graddesc/step/' + y + ',' + x, {
                    "method": "GET", credentials: 'include'
                })
                    .then(response => {
                        if (response.ok) {
                            this.error = '';
                            return response.json()
                        } else {
                            // alert("Server returned " + response.status + " : " + response.statusText);
                            this.error = 'Es ist leider ein Fehler bei der Berechnung aufgetreten.';
                        }
                    })
                    .then(response => {
                        this.currentNeighbours = response.neighbours;
                    })
                    .catch(err => {
                        this.error = 'Es ist leider ein Fehler bei der Berechnung aufgetreten.';
                        console.log(err);
                    });
            },
            getChart: function () { // or 'include'
                this.done = true;
                this.url = process.env.VUE_APP_PYTHON_SERVER_ADDRESS + '/graddesc/chart';
            },
            goBack: function () {
                this.done = false;
            }
        }
    }
</script>

<style scoped>
    .compass-grid {
        justify-self: center;
        width: 80%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 20px;
    }

    .grad-button {
        max-height: 3em;
        margin-top: 2em;
        justify-self: center;
    }

    .chart {
        width: 100%;
        height: 500px;
        border: none;
    }
</style>