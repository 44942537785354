<template>
    <div class="results-grid" :style="gridStyle">
        <div class="center-grid" v-for="(r, index) in results" v-bind:key="index">
            <img class="image shadow" :src="r._imgUrl" alt="picture of a jacket">
            <div class="shadow dark result-item"
                 :class="{green: r._correctAnswer === r._userAnswer && showUserResults,
                 red: r._correctAnswer !== r._userAnswer && showUserResults}">
                <p v-if="showUserResults" class="p-small m-5">Deine Antwort: {{r._userAnswer}}</p>
                <p class="p-small m-5">Offizielle Antwort: {{r._correctAnswer}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "image-grid",
        props:{
            showUserResults: Boolean
        },
        data(){
            return {
                results: [],
                gridColumns: 1,
                window: {
                    width: 0,
                    height: 0
                },
            }
        },
        computed: {
            gridStyle() {
                return {
                    gridTemplateColumns: `repeat(${this.gridColumns}, 1fr)`
                }
            },
        },
        created() {
            this.results = this.$store.state.results;
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
                this.calcGridCount();
            },
            calcGridCount() {
                let size = 0;
                if (this.window.width > 1000) {
                    size = Math.round((60 * this.window.width) / 100); // calc size for main without sidebar
                } else {
                    size = this.window.width;
                }
                const count = Math.round(size / 220); // calc count of image boxes with 190px + grid padding that fit into size
                if (count >= this.results.length) {
                    this.gridColumns = this.results.length;
                } else {
                    this.gridColumns = count;
                }
            },
        }
    }
</script>

<style scoped>
    .results-grid {
        display: grid;
        grid-gap: 10px;
        justify-content: center;
    }

    .result-item {
        border-radius: 0.5em;
        margin: 10px;
        padding: 0.7em;
    }

    .center-grid {
        display: grid;
        justify-items: center;
    }
</style>