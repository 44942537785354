export default class MinigameTurnResult {
    _imgUrl = '';
    _correctAnswer = '';
    _userAnswer = '';


    constructor(imgUrl, correctAnswer, userAnswer) {
        this._imgUrl = imgUrl;
        this._correctAnswer = correctAnswer;
        this._userAnswer = userAnswer;
    }


    get imgUrl() {
        return this._imgUrl;
    }

    set imgUrl(value) {
        this._imgUrl = value;
    }

    get correctAnswer() {
        return this._correctAnswer;
    }

    set correctAnswer(value) {
        this._correctAnswer = value;
    }

    get userAnswer() {
        return this._userAnswer;
    }

    set userAnswer(value) {
        this._userAnswer = value;
    }
}