export default class ButtonListContent {
    text = '';
    isColor = true;
    isDark = true;
    image = '';

    constructor(text, isColor, isDark, image) {
        this.text = text;
        this.isColor = isColor;
        this.isDark = isDark;
        this.image = image;
    }
}