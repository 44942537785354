<template>
    <div class="map-container">
        <h1>Eine Übersicht über Begriffe und Themen zu KNNs</h1>
        <h3>Eine Übersicht über Architekturen von KNNs</h3>
        <div class="map"> <!-- :style="gridStyle">-->
            <MapItem v-for="(m, index) of annsMapItems" :content="m" v-bind:key="index"></MapItem>
        </div>
        <h3>Eine Übersicht über das Erstellen von KNNs</h3>
        <div class="map"> <!-- :style="gridStyle">-->
            <MapItem v-for="(m, index) of questionsMapItems" :content="m" v-bind:key="index"></MapItem>
        </div>
    </div>
</template>

<script>
    import MapItemData from "../models/MapItemData";
    import MapItem from "../components/MapItem";

    export default {
        name: "ANNMap",
        components: {MapItem},
        data() {
            return {
                annsMapItems: [],
                questionsMapItems: [],
                gridColumns: 1,
                window: {
                    width: 0,
                    height: 0
                },
            }
        },
        computed: {
            gridStyle() {
                return {
                    gridTemplateColumns: `repeat(${this.gridColumns}, 1fr)`
                }
            },
        },
        created() {
            this.annsMapItems = [];
            this.questionsMapItems = [];
            this.annsMapItems.push(new MapItemData(
                [{img: 'domain_black_24dp.svg', color: 'var(--light-blue)'}],
                'Convolutional Neural Networks (CNNs)', 'Eine bestimmte Architektur, die besonders für die ' +
                'Bildverarbeitung verwendet wird.',
                'Diese Netze waren den nächste Schritt nach dem MLP zur Bilderkennung. Mit ihnen werden Filter auf dem ' +
                'Bild angewandt, um erst einfache Kanten und Linien, und in den tieferen Schichten komplexere Muster und ' +
                'Objekte zu erkennen.', [{
                    text: 'Eine visuelle Einführung',
                    link: 'https://www.youtube.com/watch?v=2-Ol7ZB0MmU'
                }]));
            this.annsMapItems.push(new MapItemData(
                [{img: 'domain_black_24dp.svg', color: 'var(--light-blue)'}],
                'Recurrent Neural Networks (RNNs)', 'Eine bestimmte Architektur, die besonders für (zeitlich) ' +
                'aufeinanderfolgende Daten bei der Übersetzung oder Spracherkennung verwendet wird.',
                'Das Besondere an dieser Architektur sind die Verbindungen, die auf denselben oder vorherige Knoten ' +
                'zeigen. Dadurch entstehen Schlaufen (deswegen recurrent, wiederkehrend). So können aufeinaderfolgende ' +
                'Wörter oder Töne die Ausgabe beeinflussen.', [{
                    text: 'Eine visuelle Einführung',
                    link: 'https://www.youtube.com/watch?v=UNmqTiOnRfg'
                }, {
                    text: 'Noch eine Einführung',
                    link: 'https://www.youtube.com/watch?v=LHXXI4-IEns'
                }]));
            this.annsMapItems.push(new MapItemData(
                [{img: 'domain_black_24dp.svg', color: 'var(--light-blue)'}],
                'Residual Neural Networks (ResNet)', 'Eine bestimmte Architektur, die Verbindungen besitzt, die ' +
                'Schichten überspringen.',
                'Forscher haben herausgefunden, dass es einen Punkt gibt, an dem ein Netz schlechtere Ergebnisse ' +
                'liefert, als ein Netz mit weniger Schichten. Daraufhin haben sie Verbindungen eingeführt, die Schichten ' +
                'überspringen. So konnten sie ein Netz mit sehr viel mehr Schichten aufbauen, ohne diesen Punkt der ' +
                'Verschlechterung zu erreichen.', [{
                    text: 'Das Paper',
                    link: 'https://arxiv.org/abs/1512.03385v1'
                }, {
                    text: 'Das Paper erklärt',
                    link: 'https://www.youtube.com/watch?v=GWt6Fu05voI&t=1503s'
                }]));
            this.annsMapItems.push(new MapItemData(
                [{img: 'domain_black_24dp.svg', color: 'var(--light-blue)'}],
                'Generative Adversarial Networks (GANs)', 'Eine bestimmte Architektur, die dazu verwendet wird, ' +
                'Daten (Bilder, Texte, Videos, ...) zu generieren.',
                'Diese Architektur besteht aus zwei Netzen. Das eine lernt, Bilder zu generieren, während das andere ' +
                'lernt, zu entscheiden, ob die Bilder, die es vom ersten Netz bekommt echt sind oder generiert wurden.', [{
                    text: 'Eine visuelle Einführung',
                    link: 'https://www.youtube.com/watch?v=8L11aMN5KY8'
                }, {
                    text: 'Ein GAN in Aktion',
                    link: 'https://thispersondoesnotexist.com/'
                }]));
            this.questionsMapItems.push(new MapItemData(
                [{img: 'domain_black_24dp.svg', color: 'var(--light-blue)'}],
                'Wo finde ich eine Übersicht über die aktuell besten Netze für eine Aufgabe?', 'Die Seite Papers With ' +
                'Code bietet Übersichten über alle möglichen Benchmarks und Datasets!',
                'In vielen Papers (Veröffentlichungen) werden neue Netze und Verfahren ausprobiert, deren Genauigkeit ' +
                'dann auf bestimmten Datatsets gemessen werden, um sie mit anderen Netzen zu vergleichen.', [{
                    text: 'Papers With Code',
                    link: 'https://paperswithcode.com/sota/image-classification-on-imagenet'
                }]));
            this.questionsMapItems.push(new MapItemData(
                [{img: 'domain_black_24dp.svg', color: 'var(--light-blue)'}],
                'Wie wähle ich eine Loss-Funktion?', 'Die Loss-Funktion bestimmt, wie der Fehler des Netzes berechnet wird.',
                'Es gibt verschiedene Loss-Funktionen, die für verschiedene Anwendungen besser oder schlechter geeignet sein können.', [{
                    text: 'Loss-Funktionen im Vergleich',
                    link: 'https://machinelearningmastery.com/how-to-choose-loss-functions-when-training-deep-learning-neural-networks/'
                }]));
            this.questionsMapItems.push(new MapItemData(
                [{img: 'domain_black_24dp.svg', color: 'var(--light-blue)'}],
                'Wie wähle ich einen Optimizer?', 'Der Optimizer bestimmt, auf welche Weise das Minimum der Loss-Funktion ' +
                'gefunden, also das Netz verbessert wird.',
                'Auch hier gibt es verschiedene Optimizer für verschiedene Problemstellungen.', [{
                    text: 'Optimizer im Vergleich',
                    link: 'https://towardsdatascience.com/optimizers-for-training-neural-network-59450d71caf6'
                }]));
        },
        methods: {
        }
    }
</script>

<style scoped>
    .map-container {
        background-color: var(--bg-dark);
        color: var(--text-light);
        padding: 2em;
        overflow: auto;
    }

    .map {
        display: grid;
        align-items: center;
        justify-items: start;
        grid-auto-flow: column;
        overflow-x: auto;
    }

    @media only screen and (min-width: 1400px) {
        .map-container {
            grid-column: 2 / 4;
        }
    }

    @media only screen and (max-width: 1400px) {
        .map-container {

        }
    }


</style>