<template>
    <div class="points">
        <template v-for="(i, index) in content" v-bind:key="index">
            <span><img class="dot" :src="i.icon"/></span>
            <p>{{i.text}}</p>
        </template>
    </div>
</template>

<script>
    export default {
        name: "icon-list",
        props: {
            content: Array
        }
    }
</script>

<style scoped>
    .dot {
        width: 24px;
        height: 24px;
        display: inline;
        vertical-align: middle;
        margin-right: 15px;
        filter: brightness(0) saturate(100%) invert(59%) sepia(17%) saturate(956%) hue-rotate(70deg) brightness(96%) contrast(87%);
    }

    .points {
        display: grid;
        grid-template-columns: 30px auto;
        align-items: center;
        grid-gap: 20px;
    }
</style>