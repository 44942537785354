export default class CustomComponent {
    component = '';
    props = {};
    gridArea = '';

    constructor(component, props, gridArea) {
        this.component = component;
        this.props = props;
        this.gridArea = gridArea;
    }
}