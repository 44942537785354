<template>
    <div class="perceptron-grid" :style="gridCols">
        <PixelImage class="input-data" v-if="showData" v-bind:content="inputData"></PixelImage>
        <div v-if="step > 0" class="inputs" :style="inputRows">
            <div class="input-item" :ref="'input' + index" :id="'input' + index" v-for="(i, index) in inputData.values"
                 v-bind:key="index">
                <p class="p-small m-r-5">x{{index+1}} = </p>
                <h4 class="input-circle shadow">{{i}}</h4>
            </div>

        </div>
        <div v-if="step > 1" class="weights" :style="inputRows">
            <div class="weight-item" v-for="(i, index) in inputData.values" v-bind:key="index">
                <p class="weight-label p-small">w{{index+1}} = </p>
                <input class="weight-input" type="number" maxlength="4" :ref="'wInput' + index"
                       placeholder="0,5" step="0.1" min="-1" max="1"
                       v-on:click="wInputValueChanged(index)">
            </div>
            <!--<ArrowLine v-for="(i, index) in inputData.values" v-bind:key="index"
                       :startID="'input' + index" endID="node" color="#4c4040"></ArrowLine>-->
        </div>
        <!-- placeholder for arrows -->
        <!--<div v-if="parseInt(step) === 2" id="node" class="invisible"></div>-->
        <div v-if="step > 2" id="node"
             :class="{node: step !== 3 && !nodeActive,shadow: true, nodeActive: step === 3 || nodeActive}">
            <h2 v-if="step !== 3 && !nodeActive" class="m-p-0">{{sum}}</h2>
            <!--<ArrowLine v-if="step > 3" startID="node" endID="activation" color="#4c4040"></ArrowLine>-->
            <template v-if="step === 3 || nodeActive">
                <div class="weight-item" v-for="(i, index) in inputData.values" v-bind:key="index">
                    <p class="weight-label p-small">x{{index+1}} * w{{index+1}} = </p>
                    <input class="weight-input" type="number" maxlength="4" :ref="'cInput' + index"
                           placeholder="0,4" step="0.1" value="0,6" min="0" max="1"
                           v-on:click="cInputValueChanged(index)"
                           :class="{bgreen: cCorrect[index] === true, bred: cCorrect[index] === false}">
                </div>
                <div class="weight-item">
                    <p class="weight-label p-small">&sum; = </p>
                    <input class="weight-input" type="number" maxlength="4" :ref="'sumInput'"
                           placeholder="0,5" value="0" step="0.1" min="0" v-on:click="sumInputValueChanged()"
                           :class="{bgreen: sumCorrect === true, bred: sumCorrect === false}">
                </div>
            </template>
        </div>
        <!--<div v-if="parseInt(step) === 3" id="activation" class="invisible"></div>-->
        <div v-if="step > 3" class="activation shadow" id="activation">
            <!--<h2 class="m-p-0">&fnof;</h2>-->
            <div id="chart"></div>
            <!--<ArrowLine v-if="step > 4" startID="activation" endID="output" color="#4c4040"></ArrowLine>-->
        </div>
        <!--<div v-if="parseInt(step) === 4" id="output" class="invisible"></div>-->
        <div v-if="step > 4" id="output"
             :class="{output: step !== 4 && !outputActive,shadow: true, outputActive: step === 4 || outputActive}">
            <h2 v-if="!outputActive" class="m-5">{{output}}</h2>
            <div v-if="outputActive" class="weight-item">
                <input class="weight-input" type="number" maxlength="4" :ref="'outInput'"
                       placeholder="0,5" step="0.1" min="0" max="1" v-on:click="outInputValueChanged()"
                       :class="{bgreen: outCorrect === true, bred: outCorrect === false}">
            </div>
        </div>
        <div class="output-data" v-if="showData && step > 5"></div>
        <div v-if="step > 1" class="input-lines i-l-w">
            <div class="input-line"></div>
            <div class="input-line"></div>
            <div class="input-line"></div>
            <div class="input-line"></div>

            <div class="input-end-line-container">
                <div class="input-end-line" style="--item-count: 4"></div>
            </div>
            <div class="input-connector-line"></div>
        </div>
        <div v-if="step > 3" class="input-lines i-l-n">
            <div class="input-connector-line"></div>
        </div>
        <div v-if="step > 4" class="input-lines i-l-a">
            <div class="input-connector-line"></div>
        </div>
        <div v-if="step > 1" class="input-connector-end i-c-e-n"></div>
        <div v-if="step > 3" class="input-connector-end i-c-e-a"></div>
        <div v-if="step > 4" class="input-connector-end i-c-e-o"></div>
    </div>
</template>

<script>
    import PixelImageModel from "../models/PixelImage";
    import PixelImage from "./PixelImage";
    import ArrowLine from "./ArrowLine";
    import ApexCharts from 'apexcharts'

    export default {
        name: "perceptron",
        components: {
            ArrowLine, // eslint-disable-line vue/no-unused-components
            PixelImage
        },
        props: {
            inputData: PixelImageModel,
            step: Number,
            showData: Boolean,
            nodeActive: Boolean,
            outputActive: Boolean
        },
        data() {
            return {
                gridColumns: ['input-data', 'inputs', 'weights', 'node', 'activation', 'output', 'output-data'],
                currentColumns: [],
                wInputValues: [],
                cInputValues: [],
                cCorrect: [],
                sumCorrect: false,
                sum: -1,
                output: -1,
                outCorrect: false,
                activationChart: {},
                chartOptions: {}
            }
        },
        computed: {
            inputRows() {
                return {
                    gridTemplateRows: `repeat(${this.inputData.values.length}, 1fr)`
                }
            },
            gridCols() {
                return {
                    gridTemplateColumns: `repeat(${this.step + 1}, min-content)`,
                    gridTemplateAreas: `"${this.currentColumns.join(' ')}"`
                }
            },
        },
        created() {
            this.currentColumns = [];
            this.wInputValues = [];
            this.cInputValues = [];
            this.cCorrect = [false, false, false, false];
            this.chartOptions = {
                series: [{
                    name: "Output",
                    data: [0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1]
                }],
                chart: {
                    type: 'line',
                    height: 180,
                    zoom: {
                        enabled: false
                    }
                },
                stroke: {
                    curve: 'stepline',
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    hover: {
                        sizeOffset: 4
                    }
                },
                tooltip: {
                    x: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: ['0', '0,1', '0,2', '0,3', '0,4', '0,5', '0,6', '0,7', '0,8', '0,9', '1'],
                },
                theme: {
                    mode: 'light',
                    palette: 'palette9'
                }
            };
            switch (this.step) {
                case 1:
                    if (this.showData) {
                        this.currentColumns.push(this.gridColumns[0]);
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                    } else {
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                    }
                    break;
                case 2:
                    if (this.showData) {
                        this.currentColumns.push(this.gridColumns[0]);
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                    } else {
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                    }
                    break;
                case 3:
                    if (this.showData) {
                        this.currentColumns.push(this.gridColumns[0]);
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                        this.currentColumns.push(this.gridColumns[4]);
                    } else {
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                        this.currentColumns.push(this.gridColumns[4]);
                    }
                    break;
                case 4:
                    if (this.showData) {
                        this.currentColumns.push(this.gridColumns[0]);
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                        this.currentColumns.push(this.gridColumns[4]);
                        this.currentColumns.push(this.gridColumns[5]);
                    } else {
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                        this.currentColumns.push(this.gridColumns[4]);
                        this.currentColumns.push(this.gridColumns[5]);
                    }
                    break;
                case 5:
                    if (this.showData) {
                        this.currentColumns.push(this.gridColumns[0]);
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                        this.currentColumns.push(this.gridColumns[4]);
                        this.currentColumns.push(this.gridColumns[5]);
                        this.currentColumns.push(this.gridColumns[6]);
                    } else {
                        this.currentColumns.push(this.gridColumns[1]);
                        this.currentColumns.push(this.gridColumns[2]);
                        this.currentColumns.push(this.gridColumns[3]);
                        this.currentColumns.push(this.gridColumns[4]);
                        this.currentColumns.push(this.gridColumns[5]);
                        this.currentColumns.push(this.gridColumns[6]);

                    }
                    break;
            }
            // console.log(this.step);
        },
        mounted() {
            if (this.step > 1) {
                this.$refs.wInput0.value = Math.random().toFixed(1);
                this.$refs.wInput1.value = Math.random().toFixed(1);
                this.$refs.wInput2.value = Math.random().toFixed(1);
                this.$refs.wInput3.value = Math.random().toFixed(1);
                this.wInputValueChanged(0);
                this.wInputValueChanged(1);
                this.wInputValueChanged(2);
                this.wInputValueChanged(3);
            }
            if (this.step > 2) {
                this.calcSum();
            }
            if (this.step > 3) {
                this.activationChart = new ApexCharts(document.querySelector("#chart"), this.chartOptions);
                this.activationChart.render();
                this.output = this.activationFunction(this.sum);
            }
        },
        methods: {
            wInputValueChanged: function (index) {
                switch (index) {
                    case 0:
                        this.wInputValues[index] = this.$refs.wInput0.value;
                        break;
                    case 1:
                        this.wInputValues[index] = this.$refs.wInput1.value;
                        break;
                    case 2:
                        this.wInputValues[index] = this.$refs.wInput2.value;
                        break;
                    case 3:
                        this.wInputValues[index] = this.$refs.wInput3.value;
                        break;
                }
                // console.log(this.inputValues[index] + ' ' + index);
                this.calcSum();
            },
            cInputValueChanged: function (index) {
                switch (index) {
                    case 0:
                        this.cInputValues[index] = this.$refs.cInput0.value;
                        break;
                    case 1:
                        this.cInputValues[index] = this.$refs.cInput1.value;
                        break;
                    case 2:
                        this.cInputValues[index] = this.$refs.cInput2.value;
                        break;
                    case 3:
                        this.cInputValues[index] = this.$refs.cInput3.value;
                        break;
                }
                this.cCorrect[index] = Number(this.cInputValues[index]) === Number(this.inputData.values[index]) * this.wInputValues[index];
                // console.log(this.inputValues[index] + ' ' + index);
            },
            sumInputValueChanged: function () {
                let correct = 0;
                for (let i = 0; i < this.inputData.values.length; i++) {
                    correct += this.inputData.values[i] * this.wInputValues[i];
                }
                this.sumCorrect = this.$refs.sumInput.value === Number(correct).toFixed(1);
            },
            outInputValueChanged: function () {
                this.outCorrect = Number(this.$refs.outInput.value) === this.activationFunction(this.sum);
            },
            calcSum: function () {
                let correct = 0;
                for (let i = 0; i < this.inputData.values.length; i++) {
                    correct += this.inputData.values[i] * this.wInputValues[i];
                }
                this.sum = Number(correct).toFixed(1);
            },
            activationFunction: function (x) {
                if (x > 0.5) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
    }
</script>

<style scoped>
    .perceptron-grid {
        max-width: 100%;
        display: grid;
        align-items: center;
        justify-content: center;
        overflow: auto;
        margin: 1em auto;
        padding: 1em;
    }

    .inputs {
        z-index: 1;
        display: grid;
        margin: 1em;
        grid-area: inputs;
    }

    .input-item {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        text-align: center;
        vertical-align: center;
        line-height: 1em;
        white-space: nowrap;
    }

    .m-r-5 {
        margin-right: 5px;
    }

    .m-p-0 {
        padding: 0;
    }

    .input-circle {
        width: 1em;
        height: 1em;
        background-color: var(--dark-blue);
        color: var(--text-dark);
        padding: 0.5em;
        border-radius: 50%;
        text-align: center;
        vertical-align: center;
        line-height: 1em;
        margin: 0;
    }

    .weights {
        z-index: 1;
        margin: 1em;
        grid-area: weights;
        padding-left: 10px;
    }

    .weight-item {
        display: grid;
        grid-template-columns: min-content min-content;
        grid-gap: 10px;
        color: var(--text-dark);
        justify-self: end;
        background-color: var(--bg-light);
    }

    .weight-label {
        white-space: nowrap;
        padding-left: 10px;
    }

    .weight-input {
        align-self: center;
        width: 60px;
        height: 20px;
        margin: 10px;
        border: 2px solid var(--text-dark);
        border-radius: 4px;
        background-color: var(--bg-dark-10);
    }

    .nodeActive .weight-item {
        background-color: transparent;
    }

    .node {
        z-index: 1;
        grid-area: node;
        width: 4.5em;
        height: 4.5em;
        background-color: var(--orange);
        color: var(--text-dark);
        border-radius: 50%;
        text-align: center;
        line-height: 1em;
        margin: 1em 0 1em 3em;
    }

    .nodeActive {
        z-index: 1;
        grid-area: node;
        background-color: var(--orange);
        color: var(--text-dark);
        line-height: 1em;
        margin: 1em 0 1em 3em;
        padding: 1em;
        border-radius: 5px;
        display: grid;
    }

    .activation {
        z-index: 1;
        grid-area: activation;
        display: inline;
        width: 350px;
        background-color: var(--light-blue);
        color: var(--text-dark);
        border-radius: 10px;
        text-align: center;
        vertical-align: middle;
        margin: 1em 0 1em 3em;
        padding: 1em 1em 0 0;
    }

    .output {
        z-index: 1;
        grid-area: output;
        width: 3em;
        height: 3em;
        background-color: var(--light-green);
        color: var(--text-dark);
        padding: 0.5em;
        border-radius: 50%;
        text-align: center;
        line-height: 1em;
        margin: 1em 1em 1em 3em;
    }

    .outputActive {
        z-index: 1;
        grid-area: output;
        width: 5em;
        height: 5em;
        background-color: var(--light-green);
        color: var(--text-dark);
        padding: 0.5em;
        border-radius: 50%;
        text-align: center;
        line-height: 1em;
        margin: 1em 1em 1em 3em;
        display: grid;
        align-items: center;
        justify-items: center;
    }

    .outputActive .weight-item {
        background-color: transparent;
    }

    .outputActive .weight-item .weight-input {
        margin: auto 0.5em;
    }

    .output-data {
    }

    .bred {
        border-color: var(--red);
    }

    .bgreen {
        border-color: var(--dark-green);
    }

    .input-lines {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(1, 1fr);
        grid-row: 1;
        /*margin-left: 10px;
        margin-right: -50px;*/
        position: relative;
    }

    .i-l-w {
        grid-column-start: weights;
        grid-column-end: weights;
    }

    .i-l-n {
        grid-column-start: node;
        grid-column-end: node;
    }

    .i-l-a {
        grid-column-start: activation;
        grid-column-end: activation;
    }

    .input-line {
        z-index: -1;
        grid-column: 1 / 2;
        background: var(--text-dark-70);
        height: 2px;
        align-self: center;
        margin: 28px 0;
    }

    .input-end-line-container {
        grid-column: 2 / 2;
        grid-row: 1 / 5;
        display: flex;
        align-items: center;
    }

    .input-end-line {
        background: var(--text-dark-70);
        width: 2px;
        height: calc(100% / (var(--item-count) * 2) * ((var(--item-count) - 1) * 2));
    }

    .input-connector-line {
        /*width: 100px;*/
        height: 2px;
        background: var(--text-dark-70);
        position: absolute;
        right: -30px;
        top: 50%;
        left: 100%;
    }

    .input-connector-end {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 14px solid var(--text-dark-70);
        margin-left: 30px;
    }

    .i-c-e-n {
        grid-area: node;
    }

    .i-c-e-a {
        grid-area: activation;
    }

    .i-c-e-o {
        grid-area: output;
    }

    .line-stopper {
        grid-area: line-stopper;
    }

</style>