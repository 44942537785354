<template>
    <div class="map-item shadow">
        <!--<div class="tags" :style="tagGridStyle">
            <div class="tag shadow" v-for="(t, index) in content.tags" v-bind:key="index" :style="{backgroundColor: t.color}">
                <img :src="t.img" alt="tag vqa">
            </div>
        </div>-->
        <h2 class="title">{{content.title}}</h2>
        <p class="short">{{content.short}}</p>
        <p class="desc p-small">{{content.text}}</p>
        <div class="links">
            <LinkButton v-for="(l, index) in content.urls" :text="l.text" :link="l.link"
                        color="var(--light-green)" v-bind:key="index"></LinkButton>
        </div>
    </div>
</template>

<script>
    import MapItemData from "../models/MapItemData";
    import LinkButton from "./LinkButton";

    export default {
        name: "MapItem",
        components: {LinkButton},
        props: {
            content: MapItemData
        },
        computed: {
            tagGridStyle() {
                return {
                    gridTemplateColumns: `repeat(${this.content.tags.length}, 1fr)`
                }
            },
        }
    }
</script>

<style scoped>
    .map-item {
        background-color: var(--text-light);
        color: var(--text-dark);
        border-radius: 10px;
        padding: 1em;
        margin: 1em;
        height: 500px;
        width: max-content;
        max-width: 600px;
        display: grid;
        grid-template-areas: 'title' 'short' 'desc' 'links';
    }

    .title {
        grid-area: title;
    }

    .short {
        grid-area: short;
    }
    .desc {
        grid-area: desc;
    }

    .tags {
        display: grid;
    }

    .tag {
        margin: 0.5em;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 0.5em;
    }

    .tag img {
        width: 20px;
        height: 20px;
    }

    .links {
        grid-area: links;
        display: grid;
        grid-auto-flow: column;
    }

</style>